import React from "react";
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faUsersLine, faPhoneFlip } from '@fortawesome/free-solid-svg-icons';

function About_Cost() {

    return (
        <>
            <div className="row about-cost-container mb-4" >
                <div className="col-md-4 d-flex text-start about-cost-side">
                    <div className="mt-4 p-3">
                        <FontAwesomeIcon icon={faXmark} className="about-cost-icon"/>
                    </div>
                    <div className="mt-4">
                        <h1 className="about-cost-h1">No Hidden Cost</h1>
                        <p className="about-cost-p">Clita erat ipsum lorem sit sed stet duo justo</p>
                    </div>
                    <span class="border-right"></span>
                </div>

                <div className="col-md-4 d-flex text-start about-cost-side">
                    <div className="mt-4 p-3">
                        <FontAwesomeIcon icon={faUsersLine} className="about-cost-icon"/>
                    </div>
                    <div className="mt-4">
                        <h1 className="about-cost-h1">Dedicated Team</h1>
                        <p className="about-cost-p">Clita erat ipsum lorem sit sed stet duo justo</p>
                    </div>
                </div>

                <div className="col-md-4 d-flex text-start">
                    <div className="mt-4 p-3">
                        <FontAwesomeIcon icon={faPhoneFlip} className="about-cost-icon" />
                    </div>
                    <div className="mt-4">
                        <h1 className="about-cost-h1">24/7 Available</h1>
                        <p className="about-cost-p">Clita erat ipsum lorem sit sed stet duo justo</p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default About_Cost;