import React from "react";
import './index.css';
import Image1 from '../../../Assets/images/property-loan1.jpg';
import { Link } from "react-router-dom";

function Property_Loan_Image() {

    return (
        <>
            {/* <div>
                <img src={Image1} className="d-block w-100 personal-image" alt="..." />

                <div className="image-text-business">
                    <h1 className="text-white">LOAN AGAINST PROPERTY</h1>
                    <p className="text-start">
                        <Link className="about-route-home text-white" to="/"> HOME / </Link>
                        <span className="homeloan-page-r"> Property Loan</span>
                    </p>
                </div>
            </div> */}

            <div class="property-loan-banner">
                <div class="container property-loan-banner-content">
                    <p className="text-start">
                        <h1 className="text-white banner-he-con"><strong>LOAN AGAINST PROPERTY</strong></h1>
                        <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r text-white"> Property Loan</span>
                    </p>
                </div>
            </div>


        </>
    );
}

export default Property_Loan_Image;
