import React from "react";
import './index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar, faHouseUser, faBusinessTime, faCommentDollar } from "@fortawesome/free-solid-svg-icons";
import { faHotel, faGears, faDesktop,faReceipt } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import Image1 from '../../../Assets/images/hover-icon.png'

function MiddleCard() {

    return (
        <>
            <div className="mt-4 mb-4">
                <div className="row gap-5 justify-content-center">
                    <div className="col-md-6 col-lg-3 second-card-hover-home p-5">
                        <FontAwesomeIcon icon={faBusinessTime} className="home-card-2-icon" />
                        <h6 className="mt-3 mcard-title">Business Loan</h6>
                        <p className="text-secondary">Business loan helps in your emergency
                             financial needs to develop your business in a constant way. We get the loan 
                             in short process and quick disbursement for you.
                        </p>

                        <button className="home-card-btn-apply">
                            <NavLink
                                className="nav-link nav-home"
                                to="/business-loan"
                                activeClassName="active" >
                                Apply Now
                            </NavLink>
                        </button>

                    </div>
                    <div className="col-md-6 col-lg-3 second-card-hover-home p-5">
                    {/* <FontAwesomeIcon icon={faHandHoldingDollar} className="home-card-2-icon" /> */}
                    {/* <img src={Image1} alt="ODCC-Image"  height={'60px'}/> */}
                    <FontAwesomeIcon icon={faCommentDollar} className="home-card-2-icon" />
                    <i class="fa fa-usd" aria-hidden="true"></i>
                        <h6 className="mt-3 mcard-title">OD(Over Draft) & CC(Cash Credit)</h6>
                        <p className="text-secondary">Working capital is a must for each and
                            every company for healthy growth of the company. Fresh OD/CC and
                            existing OD/CC takeover plus top-up also we can do.
                        </p>

                        <button className="home-card-btn-apply">
                            <NavLink
                                className="nav-link nav-home"
                                to="/personal-loan"
                                activeClassName="active" >
                                Apply Now
                            </NavLink>
                        </button>
                    </div>
                    <div className="col-md-6 col-lg-3 second-card-hover-home p-5">
                    <FontAwesomeIcon icon={faReceipt} className="home-card-2-icon" />
                        <h6 className="mt-3 mcard-title">Private Cheque Loan</h6>
                        <p className="text-secondary">Private cheque finance; we organize from individual financier
                            for your emergency and instance remedy for your cash needs.We having tie up with
                            50+ financiers.
                        </p>

                        <button className="home-card-btn-apply">
                            <NavLink
                                className="nav-link nav-home"
                                to="/home-loan"
                                activeClassName="active" >
                                Apply Now
                            </NavLink>
                        </button>

                    </div>

                    <div className="col-md-6 col-lg-3 second-card-hover-home p-5 ">
                    <FontAwesomeIcon icon={faHandHoldingDollar} className="home-card-2-icon" />
                        <h6 className="mt-3 mcard-title">Personal Loan</h6>
                        <p className="text-secondary">Personal Loan is a short to medium-term loan, 
                            which does not require any collateral/security, disbursed within days with less paperwork.
                        </p>

                        <button className="home-card-btn-apply">
                            <NavLink
                                className="nav-link nav-home"
                                to="/property-loan"
                                activeClassName="active" >
                                Apply Now
                            </NavLink>
                        </button>

                    </div>

                    <div className="col-md-6 col-lg-3 second-card-hover-home p-5">
                    <FontAwesomeIcon icon={faHotel} className="home-card-2-icon" />
                        <h6 className="mt-3 mcard-title">Home & LAP Loan</h6>
                        <p className="text-secondary">Every one of us like to live in own house.
                             Skanda FinCorp making it easier to buy your dream home.
                             LAP is getting the loan against on existing property loan.
                        </p>

                        <button className="home-card-btn-apply">
                            <NavLink
                                className="nav-link nav-home"
                                to="/auto-loan"
                                activeClassName="active" >
                                Apply Now
                            </NavLink>
                        </button>

                    </div>

                    <div className="col-md-6 col-lg-3 second-card-hover-home p-5">
                        {/* <FontAwesomeIcon icon={faDesktop} className="home-card-2-icon" /> */}
                        <FontAwesomeIcon icon={faGears} className="home-card-2-icon"/>
                        <h6 className="mt-3 mcard-title">Equipment & Machinery Loan  </h6>
                        <p className="text-secondary">Equipment / Machinery loans which helps 
                             to improve your company productivity.Purchase of new equipment and machinery; we make it easier 
                             by loan.
                        </p>

                        <button className="home-card-btn-apply">
                            <NavLink
                                className="nav-link nav-home"
                                to="/machinery-loan"
                                activeClassName="active" >
                                Apply Now
                            </NavLink>
                        </button>

                    </div>
                </div>
                {/* <button className="mcard-button-learn">
                    <NavLink
                        className="nav-link nav-home"
                        to="/service"
                        activeClassName="active" >
                        View All Services
                    </NavLink>
                </button> */}
            </div>
        </>
    )
}

export default MiddleCard;