import React from "react";
import './index.css';
import logo1 from '../../../src/Assets/images/skandalogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhoneVolume, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faXTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useLocation } from "react-router-dom";


function Footer() {

    return (
        <>
            <footer class="text-white py-4 footer-main-container">
                <div class="container">
                    <div class="row text-start">
                        <div class="col-md-12 col-lg-12 col-xl-5 mb-4">
                            <p>
                                We offer end-to-end financial services to our clients such as Business Loan,OD(Over Draft) & CC(Cash Credit),
                                Private Cheque Loan,<br /> Personal Loan, Home Loan,
                                Loan Against Property, Auto Loan, Equipment & Machinery Loan, Gold Loan, Insurance,<br /> Investments, and Credit Cards.
                            </p>

                        </div>

                        <div class="col-md-12 col-lg-12 col-xl-7 mb-4">
                            <div className="row">
                                <ul class="col-md-2 list-unstyled">
                                    <li>
                                        <NavLink
                                            className="nav-link nav-home"
                                            to="/"
                                            activeClassName="active" >
                                            <FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" />
                                            Home
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="nav-link nav-home"
                                            to="/service"
                                            activeClassName="active" >
                                            <FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" />
                                            Services
                                        </NavLink>
                                    </li>

                                    <li>
                                        <NavLink
                                            className="nav-link nav-home"
                                            to="/career"
                                            activeClassName="active" >
                                            <FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" />
                                            Careers
                                        </NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink
                                            className="nav-link"
                                            to="/about-us"
                                            activeClassName="active" >
                                            <FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" />
                                            About Us
                                        </NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink
                                            className="nav-link"
                                            to="/contact-us"
                                            activeClassName="active" >
                                            <FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" />
                                            Contact Us
                                        </NavLink>

                                    </li>
                                </ul>
                                <ul class="col-md-4 list-unstyled">
                                    <li>
                                        {/* <a href="#" class="text-white text-decoration-none"><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Business Loan</a> */}
                                        <NavLink
                                            className="nav-link"
                                            to="/business-loan"
                                            activeClassName="active" >
                                            <FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" />
                                            Business Loan
                                        </NavLink>
                                    </li>
                                    <li>
                                        {/* <a href="#" class="text-white text-decoration-none"><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Car Loan</a> */}
                                        <NavLink
                                            className="nav-link"
                                            to="/ODCC-loan"
                                            activeClassName="active" >
                                            <FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" />
                                            OD & CC Loan
                                        </NavLink>
                                    </li>
                                    <li>
                                        {/* <a href="#" class="text-white text-decoration-none"><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Personal Loan</a> */}
                                        <NavLink
                                            className="nav-link"
                                            to="/personal-loan"
                                            activeClassName="active" >
                                            <FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" />
                                            Private Cheque Loan
                                        </NavLink>
                                    </li>

                                    <li>
                                        {/* <a href="#" class="text-white text-decoration-none"><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Home Loan</a> */}
                                        <NavLink
                                            className="nav-link"
                                            to="/home-loan"
                                            activeClassName="active" >
                                            <FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" />
                                            Home Loan
                                        </NavLink>
                                    </li>
                                </ul>
                                <ul class="col-md-6 list-unstyled">
                                    <div className="d-flex gap-2">
                                        <span> <FontAwesomeIcon icon={faLocationDot} className="footer-location mail-size-bg mt-2" color="red" /></span>
                                        <span> No:10, Perumal Koil street, Mananchery,<br /> Kundrathur, Chennai-600069</span>
                                    </div>
                                    <h6 className="mt-1">
                                        <FontAwesomeIcon icon={faPhoneVolume} color="green" /> +91 7358515353
                                    </h6>
                                    <div className="d-flex gap-2">
                                        <small> <FontAwesomeIcon icon={faEnvelope} color="red" className="mail-size-bg mt-2" />  </small>
                                        <small>
                                            <a href="mailto:pandian@skandafincorp.com" className="gmail-link-gopage footer-gmail-link-go">
                                                peoplemindpandian@gmail.com<br />
                                                pandian@skandafincorp.com
                                            </a>
                                        </small>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <ul class=" list-unstyled d-flex gap-3 align-items-center justify-content-center">
                                <li>
                                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="footer-icon-name-1">
                                        <FontAwesomeIcon icon={faFacebookF} className="facebook-icon social-icon"
                                            color="#4267B2" /> </a>
                                </li>

                                <li>
                                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="footer-icon-name">
                                        <FontAwesomeIcon icon={faInstagram} className="instagram-icon social-icon" color="#E4405F" /></a>
                                </li>

                                <li>
                                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="footer-icon-name">
                                        <FontAwesomeIcon icon={faLinkedinIn} color=" #0A66C2" />
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <footer>
                <div className="bg-light p-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 text-start">
                                <img src={logo1} alt="Logo" width={'200px'} className="image-fluid" />
                            </div>
                            <div className="col-md-8 footer-copy-main">
                                <h6 className="footer-copyrihts mt-4">© 2025, .All rights reserved <strong>Skanda Fincorp</strong>. Designed & Developed by <a href="https://hermonsolutions.com" target="_blank">HERMON Solutions</a></h6>
                            </div>
                        </div>

                    </div>
                </div>
            </footer>

            {/* <div className="col-md-12 d-flex justify-content-between mt-4" >
                            <div className="footer-logo mt-4 mb-4">
                                <img src={logo1} alt="Logo" height={'40px'} width={'140px'} />
                            </div>
                            <div className="mt-4 text-white">
                                <h6 className="footer-copyrihts">© 2025, powered by
                                    Skanda Fincorp.
                                    All rights reserved.</h6>
                            </div>
                        </div> */}
        </>
    )
}

export default Footer;