import React from "react";
import './index.css';
import Image1 from '../../Assets/images/Investment.webp';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilterCircleDollar, faPiggyBank, faGem, faPersonWalkingLuggage, faSackDollar, faBuildingColumns } from '@fortawesome/free-solid-svg-icons';

function Invesment_About() {

    return (
        <>
            {/* <div>
                <img src={Image1} className="d-block w-100 personal-image" alt="..." />

                <div className="image-text-auto">
                    <h1 className="about-route-head text-white">INVESMENT</h1>
                    <p className="text-start">
                        <Link className="about-route-home text-white" to="/"> HOME / </Link>
                        <span className="homeloan-page-r"> Invesment</span>
                    </p>
                </div>
            </div> */}
            <div class="invesment-banner">
                <div class="container invesment-banner-content">
                    <p className="text-start">
                        <h1 className="about-route-head text-start">INVESMENT</h1>
                        <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r text-light"> Invesment</span>
                    </p>
                </div>
            </div>

            <div className="container">
                <div className="card text-start border-0 p-5 image-text-container-1 mt-4 mb-4">
                    <h1 className="insurance-card-text mt-0">About Investments</h1>

                    <p className="text-secondary">Investments are part-and-parcel of your future
                        planning – to ensure you have a secure future while you enjoy your present
                        in your own way. No matter the size, investments are imperative to help you
                        achieve your future financial goals; could be to purchase an asset, plan a
                        trip, plan for your pension or your children education, and so on.
                    </p>

                    <p className="text-secondary">It is only natural to seek high returns for your returns as quickly as possible
                        with the least risk. What you need is an expert who can guide you to achieve
                        your goals!
                    </p>

                    <p className="text-secondary">The top 6 investment options to meet your
                        financial goals.
                    </p>
                </div>

                <div className="row">

                    <div className="card col-md-6 p-5 border-0">
                        <FontAwesomeIcon icon={faFilterCircleDollar} className="home-card-2-icon" />
                        <h5 className="mt-3"><strong>Equity Mutual Funds</strong></h5>

                        <p className="text-secondary text-start mt-3">Debt mutual fund schemes are suitable for investors
                            who want steady returns. They are less volatile and, hence, considered less
                            risky compared to equity mutual funds. Debt mutual funds primarily invest in
                            fixed-interest generating securities like corporate bonds, government securities,
                            treasury bills, commercial paper and other money market instruments.
                        </p>


                    </div>

                    <div className="card col-md-6 p-5 border-0">
                        <FontAwesomeIcon icon={faPiggyBank} className="home-card-2-icon" />

                        <h5 className="mt-3"><strong>Debt Mutual Funds</strong></h5>

                        <p className="text-secondary text-start mt-3">Equity mutual fund schemes invest predominantly in equity stocks – across different sectors, sizes of companies listed on the stock exchanges. The benefit of investing in an equity Mutual Fund is that are indeed investing in the stock market but without the hassle of tracking your investments since the Fund Manager is accountable for generating returns.
                        </p>

                    </div>

                    <div className="card col-md-6 p-5 border-0">
                        <FontAwesomeIcon icon={faBuildingColumns} className="home-card-2-icon" />
                        <h5 className="mt-3"><strong>Bank fixed deposit</strong></h5>

                        <p className="text-secondary text-start mt-3">A bank fixed deposit is considered
                            a comparatively safer (than equity or mutual funds) choice, but with lower
                            returns. As per the need, one may opt for monthly, quarterly, half-yearly,
                            yearly or cumulative interest option in them. The interest rate earned
                            is added to one’s income and is taxed as per one’s income slab.
                        </p>


                    </div>

                    <div className="card col-md-6 p-5 border-0">
                        <FontAwesomeIcon icon={faSackDollar} className="home-card-2-icon" />

                        <h5 className="mt-3"><strong>Public Provident Fund  </strong></h5>

                        <p className="text-secondary text-start mt-3">The PPF is one product a lot of
                            people turn to. Since the PPF has a long tenure of 15 years, the impact of
                            compounding of tax-free interest is huge, especially in the later years.
                            Further, since the interest earned and the principal invested is backed
                            by sovereign guarantee, it makes it a safe investment.
                        </p>

                    </div>

                    <div className="card col-md-6 p-5 border-0">
                        <FontAwesomeIcon icon={faPersonWalkingLuggage} className="home-card-2-icon" />
                        <h5 className="mt-3"><strong>Direct equity</strong></h5>

                        <p className="text-secondary text-start mt-3">Investing in stocks directly is
                            not an easy job – you need the right advice to pick the right stock and
                            time your entry and exit. The silver lining is that over long periods,
                            equity has been able to deliver higher than inflation-adjusted returns.
                        </p>


                    </div>

                    <div className="card col-md-6 p-5 border-0">
                        <FontAwesomeIcon icon={faGem} className="home-card-2-icon" />

                        <h5 className="mt-3"><strong>Gold</strong></h5>

                        <p className="text-secondary text-start mt-3">Investment in paper gold is more
                            cost-effective and can be done through gold exchange traded funds (ETFs).
                            Investing in Sovereign Gold Bonds is another option to own paper-gold. An
                            investor can also invest via gold mutual funds.
                        </p>

                    </div>

                </div>
            </div>

        </>
    );
}

export default Invesment_About;
