import React, { useState, useEffect } from "react";
import Navbar from "../../../component/navbar";
import './index.css';
import Title from "../../../component/title";
import Footer from "../../../component/footer";
import PersonalLoan from "../../../component/Loans/Personal-loan";
import PersonalAbout from "../../../component/Loans/Personal-loan/about";
import Personal_Form from "../../../component/Loans/Personal-loan/form";
import Personal_Offer from "../../../component/Loans/Personal-loan/offer";
import Personal_Choose from "../../../component/Loans/Personal-loan/choose";
import Personal_Final from "../../../component/Loans/Personal-loan/final";
import Loading from "../../../component/loading";

function Personal_Loan() {
 const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>

            <Title title="Personal Loan | Skanda Fincorp">

                <Navbar />

                <PersonalLoan />
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="container mt-4 mb-4">
                            <PersonalAbout />

                            <Personal_Form />

                            <Personal_Offer />

                        </div>
                        <div className="full-personal-container p-5">
                            <h1 className="full-personal-header">Get financing for whatever you need now</h1>

                            <button className="full-container-btn mt-4">Enquire Now</button>

                        </div>
                        <div className="container mt-4 mb-4">
                            <Personal_Choose />

                            <Personal_Final />
                        </div>
                        <Footer />
                    </>
                )}
            </Title>

        </>
    )
}

export default Personal_Loan;