import React, { useState } from "react";
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import logo from "../../../src/Assets/images/skandalogo.png";
import { NavLink, useLocation } from "react-router-dom";

function Navbar() {
    const location = useLocation();
    const isActive = ['/personal-loan', '/home-loan', '/business-loan', '/property-loan'
        , '/auto-loan', '/machinery-loan', '/PrivateCheque-Loan', '/ODCC-loan', '/gold-loan'].includes(location.pathname);

    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };


    return (
        <>
            <div className="first-navbar navbar-message p-2">
                <div class="container ">
                    <div className="row">
                        <div className="col-md-7 col-lg-6 text-start">
                            <span className="me-3 first-nav-p"> Right distination for all loans | Reach us: +917358515353</span>
                            {/* <span className="me-3 first-nav-p2"> Reach us : +91 7358515353</span> */}
                        </div>
                        <div className="col-md-5 col-lg-6 text-end">
                            <span>
                                <NavLink className="emi-cal-name"
                                    to="/emi-calculator"
                                > Emi Calculator</NavLink>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg navbar shadow-sm second-navbar sticky-top">
                <div className="container">
                    <NavLink clNavLinkssName="navbar-brand d-flex align-items-center" to={'/'}>
                        <img src={logo} alt="Logo" width={'200px'} />
                    </NavLink>

                    <button
                        className={`navbar-toggle ${isCollapsed ? 'collapsed' : 'x'}`}
                        type="button"
                        onClick={handleToggle}
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded={!isCollapsed}
                        aria-label="Toggle navigation"
                    >
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>



                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link nav-home"
                                    to="/"
                                    activeClassName="active" >
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className={`nav-link navbar_text ${isActive ? 'active' : ''}`}
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Loans <FontAwesomeIcon icon={faChevronDown} className="chevrondown" />
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <NavLink className="dropdown-item"
                                            to="/business-loan"
                                            activeClassName="active">
                                            Business Loan
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item"
                                            to="/ODCC-loan"
                                            activeClassName="active">
                                            OD & CC Loan
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item"
                                            to="/PrivateCheque-Loan"
                                            activeClassName="active">
                                            Private Cheque Loan
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item"
                                            to="/personal-loan"
                                            activeClassName="active">
                                            Personal Loan
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item"
                                            to="/home-loan"
                                            activeClassName="active">
                                            Home Loan
                                        </NavLink>
                                    </li>

                                    <li>
                                        <NavLink className="dropdown-item"
                                            to="/property-loan"
                                            activeClassName="active">
                                            Loan Against Property
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item"
                                            to="/auto-loan"
                                            activeClassName="active">
                                            Auto Loan
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item"
                                            to="/machinery-loan"
                                            activeClassName="active">
                                            Machinery Loan
                                        </NavLink>
                                    </li>

                                    <li>
                                        <NavLink className="dropdown-item"
                                            to="/gold-loan"
                                            activeClassName="active">
                                            Gold Loan
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <NavLink
                                    className="nav-link nav-home"
                                    to="/insurance"
                                    activeClassName="active" >
                                    Insurance
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="/invesment"
                                    activeClassName="active" >
                                    Invesments
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="/cibil-score"
                                    activeClassName="active" >
                                    Cibil score
                                </NavLink>
                            </li>

                            <li>
                                <form className="search">
                                    <div className="search__wrapper">
                                        <input type="text" name="" placeholder="Search" className="search__field" />
                                        <button type="submit" class=" search__icon"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                                    </div>
                                </form>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>


        </>
    );
}

export default Navbar;
