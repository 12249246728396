import React, { useState } from "react";
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function Personal_Final() {

    const [icons, setIcons] = useState({
        collapseOne: "+",
        collapseTwo: "+",
        collapseThree: "+",
        collapseFour: "+",
        collapseFive: "+",
    });

    const [openSections, setOpenSections] = useState({
        collapseOne: false,
        collapseTwo: false,
        collapseThree: false,
        collapseFour: false,
        collapseFive: false,

    });

    const toggleCollapse = (section) => {
        setOpenSections((prev) => {
            const newState = { ...prev, [section]: !prev[section] };
            setIcons((prevIcons) => ({
                ...prevIcons,
                [section]: newState[section] ? "-" : "+",
            }));
            return newState;
        });
    };

    return (
        <>
            <div className="card mt-5 policy-main-co p-5">

                <h1 className="offer-header text-start">Frequently Ask Questions</h1>
                <p className="offer-content text-start">If you have a question that deals with
                    clients, customers or the public in general, there is bound to be a need
                    for the FAQ page.</p>

                {/* Section 1 */}
                <div className="card border-0">
                    <div className="card-header policy-bg">
                        <h5 className="mb-0 ">
                            <a
                                className="d-flex justify-content-between align-items-center text-decoration-none policy-head-personal"
                                role="button"
                                onClick={() => toggleCollapse("collapseOne")}
                            >
                                Why do i take a Personal Loan ?
                                <span className="toggle-icon">{icons.collapseOne}</span>
                            </a>
                        </h5>
                    </div>
                    {openSections.collapseOne && (
                        <div className="card-body text-start">
                            <p>Personal loans can be used to finance anything from funding an
                                unexpected medical emergency, marriage, family vacation, home
                                renovation to even education fees.</p>

                            <p>Personal loan interest rates are mostly fixed and higher than
                                other loan types. If you have a low CIBIL score, then you
                                should not apply in multiple banks for loans as each rejection
                                will drop your CIBIL score.</p>
                        </div>
                    )}
                </div>

                {/* Section 2 */}
                <div className="card mt-3 border-0">
                    <div className="card-header policy-bg">
                        <h5 className="mb-0">
                            <a
                                className="d-flex justify-content-between align-items-center text-decoration-none policy-head-personal"
                                role="button"
                                onClick={() => toggleCollapse("collapseTwo")}
                            >
                                How much can I borrow on a Personal loan?

                                <span className="toggle-icon">{icons.collapseTwo}</span>
                            </a>
                        </h5>
                    </div>
                    {openSections.collapseTwo && (
                        <div className="card-body text-start">
                            <p>Normally it can go beyond 60% of net take-home salary. Most banks
                                prescribe certain limits on Personal loan based on your income and
                                ability to repay. However, it is always advisable to borrow an
                                amount that you will be able to safely repay without much
                                discomfort.</p>
                            <p>The personal loan amount you are eligible for is also dependent on
                                some other important factors like the bank-wise company category
                                listing for the same bankers and the banking credit history in
                                the last few years.</p>
                        </div>
                    )}
                </div>

                {/* Section 3 */}
                <div className="card mt-3 border-0">
                    <div className="card-header policy-bg">
                        <h5 className="mb-0">
                            <a
                                className="d-flex justify-content-between align-items-center text-decoration-none policy-head-personal"
                                role="button"
                                onClick={() => toggleCollapse("collapseThree")}
                            >
                                What is the CIBIL score for personal loan?
                                <span className="toggle-icon">{icons.collapseThree}</span>
                            </a>
                        </h5>
                    </div>
                    {openSections.collapseThree && (
                        <div className="card-body text-start">
                            <p><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> CIBIL score ranges from 300 to 900. A score of 750 and above is
                                considered a good score to get personal loan.</p>
                            <p><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Higher the credit score, the higher the chances to get the
                                loan application approved.</p>
                        </div>
                    )}
                </div>

                {/* Section 4*/}
                <div className="card mt-3 border-0">
                    <div className="card-header policy-bg">
                        <h5 className="mb-0">
                            <a
                                className="d-flex justify-content-between align-items-center text-decoration-none policy-head-personal"
                                role="button"
                                onClick={() => toggleCollapse("collapseFour")}
                            >
                                What are the tax benefits available on personal loans?
                                <span className="toggle-icon">{icons.collapseThree}</span>
                            </a>
                        </h5>
                    </div>
                    {openSections.collapseFour && (
                        <div className="card-body text-start">
                            <p> <FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> However, you may be able
                             to claim interest you’ve paid when you file your taxes if you take out a loan or accrue credit card 
                             charges to finance business expenses.  </p>
                            <p><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Interest on qualified student 
                            loans, which are used to pay for qualified educational expenses, is tax deductible.
                            </p>
                        </div>
                    )}
                </div>

                {/* Section 5 */}
                <div className="card mt-3 border-0">
                    <div className="card-header policy-bg">
                        <h5 className="mb-0">
                            <a
                                className="d-flex justify-content-between align-items-center text-decoration-none policy-head-personal"
                                role="button"
                                onClick={() => toggleCollapse("collapseFive")}
                            >
                                Personal Loan vs Credit Card Loan: Which is the Right Choice for You?
                                <span className="toggle-icon">{icons.collapseThree}</span>
                            </a>
                        </h5>
                    </div>
                    {openSections.collapseFive && (
                        <div className="card-body text-start">
                            <p>
                                For loans without submitting any collateral or guarantee,
                                personal loan and the credit card loan offers the best option.
                                But do you know which is the better option between the two? We will
                                discuss it here today. </p>

                            <p><strong>Personal Loan </strong></p>

                            <p><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Loan for various purposes such as weddings, traveling, medical expenses, children’s education, etc. </p>
                            <p><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Eligible for a higher loan amount depending on your income and CIBIL score.  </p>
                            <p><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Tenure of loan maximum of 5 years.</p>
                            <p><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> The low-interest-rate compares to loans on the credit card. The interest rate depends on your credit profile.</p>
                            <p><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Loan to be repaid as EMIs for a specific tenure.</p>

                            <p><strong>Credit Card</strong></p>

                            <p><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Loan Credit Card for small and big purchases for personal or business needs.</p>
                            <p><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Can use amount up to the predetermined monthly credit limit.</p>
                            <p><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Interest-free credit period of upto 50 days.</p>
                            <p><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Loan Credit Card interest rate is higher than personal loans if late or part payments.</p>
                            <p><FontAwesomeIcon icon={faAngleRight} className="right-arrow-footer" /> Credit Card bill to be paid at the end of credit period.</p>

                        </div>
                    )}
                </div>
            </div>
        </>

    );
}

export default Personal_Final;
