import React, { useState, useEffect } from "react";
import Navbar from "../../component/navbar";
// import './index.css';
import Title from "../../component/title";
import Footer from "../../component/footer";
import Emi_cal from "../../component/emi-calculator";
import Loading from "../../component/loading";

function EMI_Calculator() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>

            <Title title="EMI-Cal | Skanda Fincorp">

                <Navbar />

                <Emi_cal />
                {isLoading ? (
                    <Loading />

                ) : (
                    <>
                        <Footer />
                    </>
                )}
            </Title>

        </>
    )
}

export default EMI_Calculator;