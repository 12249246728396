import React, { useState, useEffect } from "react";
import Navbar from "../../component/navbar";
import Images from "../../component/home/images";
import Card from "../../component/home/card";
import MiddleCard from "../../component/home/second card";
import Banner from "../../component/home/text-banner";
import './index.css';
import Title from "../../component/title";
import Footer from "../../component/footer";
import Hover_card from "../../component/home/hover-image";
import Pricing_card from "../../component/home/pricing";
import { NavLink } from "react-router-dom";
import Loading from "../../component/loading";

function Home() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);
    return (
        <>

            <Title title="HOME | Skanda Fincorp">

                <Navbar />
                <Images />
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="container">
                            <Card />
                            <MiddleCard />
                        </div>
                        <div className="banner-hole-con">
                            <div className="p-4">
                                <Banner />
                            </div>
                            <div className="banner-footer-con p-4 d-flex justify-content-center gap-4">
                                <p className="banner-foot-para mt-2">Learn the Cost of your <strong>Skanda-Fincorp</strong> Right Now! </p>
                                {/* <button className="">VIEW ALL PRICING</button> */}
                                <button className="banner-foot-btn">
                                    <NavLink
                                        className="nav-link nav-home"
                                        to="/emi-calculator"
                                        activeClassName="active" >
                                        VIEW EMI CALCULATOR
                                    </NavLink>
                                </button>
                            </div>
                        </div>
                        <div className="container">
                            <Hover_card />
                        </div>

                        <div className="banner-hole-con">
                            <div className="p-4">
                                <Pricing_card />
                            </div>
                        </div>

                        <Footer />
                    </>
                )}
            </Title>

        </>
    )
}

export default Home;