import React from "react";
import './index.css';
import Image1 from '../../../Assets/images/home-loan1.jpg';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTentArrowTurnLeft } from '@fortawesome/free-solid-svg-icons';

function Home_Loan_Image() {

    return (
        <>
            {/* <div>
                <img src={Image1} className="d-block w-100 personal-image" alt="..." />

                <div className="image-text-home">
                    <h1 className="about-route-head">HOME LOAN</h1>
                    <p className="text-start">
                        <Link className="about-route-home" to="/"> HOME / </Link>
                        <span className="homeloan-page-r"> Home Loan</span>
                    </p>
                </div>
            </div> */}
             <div class="home-banner">
                <div class="container home-banner-content">
                    <p className="text-start">
                        <h1 className="banner-he-con"><strong>HOME LOAN</strong></h1>
                        <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r"> Home Loan</span>
                    </p>
                </div>
            </div>

        </>
    );
}

export default Home_Loan_Image;
