import React from "react";
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faLightbulb, faHandHoldingDollar, faGears } from '@fortawesome/free-solid-svg-icons';

function Personal_Choose() {

    return (
        <>

            <div className="card personal-choose-card p-5">

                <h1 className="offer-header">Why should you choose us anyway?</h1>
                <p className="offer-content">Here’s a sneak peak of some features of our personal loan:</p>

                <div className="row mt-4">

                    <div className="col-md-6">
                    <FontAwesomeIcon icon={faClock} className="home-card-2-icon"/>
                        <h4 className="mt-4 personal-choose-header">1.Faster loan processing</h4>
                        <p className="offer-content">During medical emergencies, we understand the time will be ticking and hence, process your personal loan faster with no collateral verification.</p>
                    </div>
                    <div className="col-md-6">
                    <FontAwesomeIcon icon={faLightbulb} className="home-card-2-icon" />
                        <h4 className="mt-4 personal-choose-header">2.Choose your amount</h4>
                        <p className="offer-content">You have the liberty to choose the amount and write that in the loan quotation form.</p>
                    </div>
                    <div className="col-md-6 mt-4">
                    <FontAwesomeIcon icon={faHandHoldingDollar} className="home-card-2-icon" />
                        <h4 className="mt-4 personal-choose-header">3.Best rates</h4>
                        <p className="offer-content">We offer reasonable rates for your personal loan, which indicates transparency.</p>
                    </div>
                    <div className="col-md-6 mt-4">
                    <FontAwesomeIcon icon={faGears} className="home-card-2-icon"/>
                        <h4 className="mt-4 personal-choose-header">4.Choose your tenure</h4>
                        <p className="offer-content">Along with choosing your amount, you also have the choice of choosing your tenure.</p>
                    </div>

                </div>

            </div>

        </>
    );
}

export default Personal_Choose;
