import React from "react";
import "./index.css";
import Image1 from "../../Assets/images/contact-us.jpg";
import { Link } from "react-router-dom";

function Contact_Image() {

    return (
        <>
            {/* <div>
                <img src={Image1} className="d-block w-100 personal-image" alt="EMI Calculator" />
                <div className="image-text-auto-cibil">
                    <h1 className="emi-image-head text-white">Contact-Us</h1>
                    <p className="text-start">
                        <Link className="about-route-home text-white" to="/">
                            HOME /
                        </Link>
                        <span className="homeloan-page-r"> Contact-Us</span>
                    </p>
                </div>
            </div> */}

            <div class="contact-banner">
                <div class="container contact-banner-content">
                    <p className="text-start">
                        <h1 className="text-light"><strong>CONTACT US</strong></h1>
                        <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r text-light"> Contact Us</span>
                    </p>
                </div>
            </div>

        </>
    );
}

export default Contact_Image;
