import React, { useRef, useState } from "react";
import './index.css';
import Image1 from '../../../Assets/images/home-loan1.jpg';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faAngleLeft, faAngleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Image2 from '../../../Assets/images/odcc-2-img.jpg';
import Image3 from '../../../Assets/images/privatecheque.jpg';
import Image4 from '../../../Assets/images/Personal-Loan.jpg';
import Image5 from '../../../Assets/images/ODCC-img.avif';
import Image6 from '../../../Assets/images/property-loan1.jpg';
import Image7 from '../../../Assets/images/car-loan-12.jpg';
import Image8 from '../../../Assets/images/machinary-loan.jpg';
import Image9 from '../../../Assets/images/gold-loan.jpg';

function Business_Loan_Apply() {
    const [activeStep, setActiveStep] = useState(1);

    const handleStepClick = (step) => {
        setActiveStep(step);
    };

    const [activeTab, setActiveTab] = useState("credit");

    // Navigation Click Handler
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const containerRef = useRef(null);
    const containerRef1 = useRef(null);
    const totalSteps = 6; // Total number of steps
    const visibleSteps = 3; // Number of steps to show at once
    const stepWidth = 300; // Width of each step, adjust accordingly
    const maxScroll = (totalSteps - visibleSteps) * stepWidth;

    const handleScroll = (direction) => {
        if (containerRef.current) {
            const stepWidth = 300; // Width of each step
            const currentScroll = containerRef.current.scrollLeft;

            const scrollAmount = direction === 'next' ? stepWidth : -stepWidth;
            const newScrollPosition = currentScroll + scrollAmount;

            if (newScrollPosition >= 0 && newScrollPosition <= maxScroll) {
                containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            }
        }
    };


    const handleScroll1 = (direction) => {
        if (containerRef1.current) {
            const scrollAmount = direction === "next" ? 300 : -300; // Adjust step width as needed
            containerRef1.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
        }
    };


    return (
        <>

            <div className="card p-3 text-start border-0 personal-about-card">

                <h4 className="personal-card-header">Business Loan</h4>
                <p> Fuel Your Growth with Skanda Fincorp,
                    Need a business loan in Chennai? Skanda Fincorp offers a range of
                    flexible and affordable business loan options to suit your needs. Apply online
                    today and get quick approvals!</p>
            </div >
            <div className="card p-3 text-start border-0 personal-about-card">
                <div className="row">
                    <div className="col-md-12 d-flex">
                        <button
                            className="carousel-btn prev"
                            onClick={() => handleScroll("prev")}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <ul className="business-card-ul" ref={containerRef}>

                            {/* <li
                                className={`step ${activeStep === 1 ? "active" : ""}`}
                                onClick={() => handleStepClick(1)}
                            >
                                <span className="step-title">Unlocking Business Potential</span>
                            </li>
                            <li
                                className={`step ${activeStep === 2 ? "active" : ""}`}
                                onClick={() => handleStepClick(2)}
                            >
                               
                                <span className="step-title">Our Business Loan Offerings in Chennai</span>
                            </li>
                            <li
                                className={`step ${activeStep === 3 ? "active" : ""}`}
                                onClick={() => handleStepClick(3)}
                            >
                               
                                <span className="step-title">Unlock Best Business Loan Offers for meeting your Capital requirements</span>
                            </li> */}
                            <li
                                className={`step ${activeStep === 1 ? "active" : ""}`}
                                onClick={() => handleStepClick(1)}
                            >
                                {/* <span className="step-icon mt-4">4</span> */}
                                <span className="step-title">Benefits</span>
                            </li>
                            <li
                                className={`step ${activeStep === 2 ? "active" : ""}`}
                                onClick={() => handleStepClick(2)}
                            >
                                {/* <span className="step-icon mt-4">4</span> */}
                                <span className="step-title">Features</span>
                            </li>
                            <li
                                className={`step ${activeStep === 5 ? "active" : ""}`}
                                onClick={() => handleStepClick(5)}
                            >
                                {/* <span className="step-icon mt-4">4</span> */}
                                <span className="step-title">Business Loan Eligibility</span>
                            </li>
                            <li
                                className={`step ${activeStep === 6 ? "active" : ""}`}
                                onClick={() => handleStepClick(6)}
                            >
                                {/* <span className="step-icon mt-4">4</span> */}
                                <span className="step-title">Disclaimer</span>
                            </li>

                        </ul>
                        <button
                            className="carousel-btn next"
                            onClick={() => handleScroll("next")}
                            disabled={containerRef.current?.scrollLeft >= (totalSteps - visibleSteps) * stepWidth}
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                    <div className="col-md-12 card p-4 mt-4 rounded-0">
                        {/* {activeStep === 1 && (
                            <div className="text-start">
                                <p> <strong className="business-header-about"> Unlocking Business Potential:</strong> <br />Accessing Business Loans in Chennai
                                    Starting a business or expanding an existing one requires capital. At Skanda Fincorp,
                                    we understand the financial needs of businesses in Chennai and offer a diverse range
                                    of business loans tailored to meet your unique requirements.</p>
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div className="text-start">
                                <p>
                                    <strong className="business-header-about"> Our Business Loan Offerings in Chennai:</strong>
                                </p>
                                <p><strong>Working Capital Loans:</strong><br />
                                    Bridge cash flow gaps, manage day-to-day expenses, and ensure smooth operations.
                                </p>
                                <p> <strong> Term Loans:</strong><br /> Fund long-term investments such as equipment
                                    purchases, business expansion, and infrastructure development.</p>
                                <p>  <strong>MSME Loans:</strong><br /> Cater specifically to the needs of Micro,
                                    Small, and Medium Enterprises with flexible repayment options.</p>
                                <p>
                                    <strong>Competitive Interest Rates:</strong><br /> Enjoy attractive interest rates
                                    that make your loan more affordable.</p>
                                <p>
                                    <strong>Quick Disbursal:</strong> <br />
                                    Receive funds quickly to seize opportunities promptly.</p>
                                <p>
                                    <strong> Minimal Documentation:</strong> <br />Simplify the application process with
                                    minimal paperwork.</p>
                                <p>
                                    <strong> Flexible Repayment Options:</strong><br /> Choose repayment schedules that
                                    align with your cash flow.
                                </p>
                                <p>
                                    <strong>Transparent and Hassle-Free Process:</strong><br /> Experience a smooth and transparent loan application and approval process.
                                </p>
                                <p><strong> Dedicated Customer Support:</strong><br />
                                    Our team of experts is available to guide you through every step of the loan process.
                                </p>
                            </div>
                        )}
                        {activeStep === 3 && (
                            <div className="text-start">
                                <h6 className="business-header-about">
                                    Unlock Best <span className="business-head-bus">Business Loan</span> Offers for
                                    meeting your Capital requirements
                                </h6>
                                <p className="business-hr"></p>
                                <small>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Best Offers from 10+ Lenders.</small><br />
                                <small>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Hassle-Free Documentation.</small><br />
                                <small>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Quick Disbursal.</small><br />
                            </div>
                        )} */}
                        {activeStep === 1 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Benefits</h6>
                                <p className="business-hr"></p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Both secured & unsecured loans are available.</p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Loan amount:</strong> Unsecured loan - Up to Rs 1 crore; Secured loans - Up to Rs 5 crore.</p>

                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Loan tenure:</strong> Unsecured loan – Up to 5 years; Secured loans - Up to 15 years.</p>

                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Unsecured loan facilities include term loans & working capital loans.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Secured loan facilities include Cash Credit A/c & Working Capital Loans.
                                </p>
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div className="text-start">
                                {/* <h6 className="business-header-about"> Features:</h6> */}
        
                                <h6 className="business-header-about"> Our Business Loan Offerings in Chennai:</h6>
                                <p className="business-hr"></p>

                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Working Capital Loans:</strong>
                                    Bridge cash flow gaps, manage day-to-day expenses, and ensure smooth operations.
                                </p>
                                <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong> Term Loans:</strong> Fund long-term investments such as equipment
                                    purchases, business expansion, and infrastructure development.</p>
                                <p>  <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>MSME Loans:</strong> Cater specifically to the needs of Micro,
                                    Small, and Medium Enterprises with flexible repayment options.</p>

                                <h6 className="business-header-about">Why Choose Skanda Fincorp for Your Business Loan Needs in Chennai?</h6>
                                <p className="business-hr"></p>

                                <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Competitive Interest Rates:</strong> Enjoy
                                    attractive interest rates that make your loan more affordable.</p>
                                <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Quick Disbursal:</strong> Receive funds
                                    quickly to seize opportunities promptly.</p>
                                <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>  Minimal Documentation:</strong> Simplify the
                                    application process with minimal paperwork.</p>
                                <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Flexible Repayment Options:</strong>  Choose
                                    repayment schedules that align with your cash flow.</p>
                                <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Transparent and Hassle-Free Process:</strong>  Experience a
                                    smooth and transparent loan application and approval process.</p>
                                <p>  <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Dedicated Customer Support:</strong>  Our team of experts
                                    is available to guide you through every step of the loan process. </p>

                            </div>
                        )}
                        {activeStep === 5 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Business Loan Eligibility</h6>
                                <p className="business-hr"></p>
                                <p><strong><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Age:</strong> 21 - 60 years.</p>
                                <p><strong><FontAwesomeIcon icon={faCircle} className="business-dot-icon" />  Business Vintage:</strong>1 year & above.</p>
                                <p><strong><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Credit Score:
                                </strong> A credit score of 675 & above increase the chances of loan approval and at lower interest rates.</p>
                            </div>
                        )}
                        {activeStep === 6 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Disclaimer</h6>
                                <p className="business-hr"></p>
                                <p>  Skanda Fincorp acts as a loan aggregator, connecting you with our network of trusted lending partners.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="carousel-container">
                <button
                    className="carousel-btn1 prev"
                    onClick={() => handleScroll1("prev")}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <div className="carousel-wrapper d-flex" ref={containerRef1}>
                    <div className="product-card">
                        <img src={Image2} className='product-card-img' alt="business loan" />
                        <p className="text-truncate">OD & CC Loan</p>
                        <Link to={'/ODCC-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image3} className='product-card-img' alt="business loan" />
                        <p className="text-truncate">Private Cheque Loan</p>
                        <Link to={'/PrivateCheque-Loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image4} className='product-card-img' alt="business loan" />
                        <p className="text-truncate">Personal Loan</p>
                        <Link to={'/personal-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image5} className='product-card-img' alt="business loan" />
                        <p className="text-truncate">Home Loan</p>
                        <Link to={'/home-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image6} className='product-card-img' alt="business loan" />
                        <p className="text-truncate">Loan Against Property</p>
                        <Link to={'/property-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image7} className='product-card-img' alt="business loan" />
                        <p className="text-truncate">Auto Loan</p>
                        <Link to={'/auto-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image8} className='product-card-img' alt="business loan" />
                        <p className="text-truncate">Machinery Loan</p>
                        <Link to={'/machinery-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image9} className='product-card-img' alt="business loan" />
                        <p className="text-truncate">Gold Loan</p>
                        <Link to={'/gold-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                </div>
                <button
                    className="carousel-btn1 next"
                    onClick={() => handleScroll1("next")}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
            </div>

            <div className="form-container mt-4 mb-4">
                <div className="card p-5 border-0 form-card">
                    <h2 className="form-header-con">
                        Apply Now!
                    </h2>
                    <p className="text-secondary">Now apply for a Business Loan online, All you need
                        to do is provide your details below application form.</p>
                    <form action="#" >
                        <div className="form-row">
                            <div className="input-data">
                                <input type="text" required />
                                <div className="underline"></div>
                                <label for="" >Name</label>
                            </div>
                            <div className="input-data">
                                <input type="text" required />
                                <div className="underline"></div>
                                <label for="">E-Mail</label>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-data">
                                <input type="text" required />
                                <div className="underline"></div>
                                <label for="">Place</label>
                            </div>
                            <div className="input-data">
                                <input type="text" required />
                                <div className="underline"></div>
                                <label for="">Phone</label>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="input-data">
                                <input type="text" required />
                                <div className="underline"></div>
                                <label for="" className="form-name">Loan Amount</label>
                            </div>
                            <div className="input-data">
                                <input type="text" required />
                                <div className="underline"></div>
                                <label for="" className="form-name-1">Year</label>
                            </div>
                        </div>
                        <button className="form-btn-page mt-4">Apply Now</button>
                    </form>
                </div>
            </div>

            {/* <div class="container mt-5">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="benefits-tab" data-bs-toggle="tab" data-bs-target="#benefits" type="button"
                            role="tab" aria-controls="benefits" aria-selected="true">Benefits</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="features-tab" data-bs-toggle="tab" data-bs-target="#features" type="button"
                            role="tab" aria-controls="features" aria-selected="false">Features</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="eligibility-tab" data-bs-toggle="tab" data-bs-target="#eligibility" type="button"
                            role="tab" aria-controls="eligibility" aria-selected="false">Eligibility Criteria</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="documents-tab" data-bs-toggle="tab" data-bs-target="#documents" type="button"
                            role="tab" aria-controls="documents" aria-selected="false">Document Required</button>
                    </li>
                </ul>
                <div class="tab-content p-4 border border-top-0" id="myTabContent">
                    <div class="tab-pane fade show active" id="benefits" role="tabpanel" aria-labelledby="benefits-tab">
                        <ul>
                            <li>Nil Processing Charges</li>
                            <li>Loan is provided up to 90% of the Face Value or Book value</li>
                            <li>No maximum Loan limit</li>
                            <li>Nil Pre-payment Charges</li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="features" role="tabpanel" aria-labelledby="features-tab">
                        <p>Details about the features go here.</p>
                    </div>
                    <div class="tab-pane fade" id="eligibility" role="tabpanel" aria-labelledby="eligibility-tab">
                        <p>Details about eligibility criteria go here.</p>
                    </div>
                    <div class="tab-pane fade" id="documents" role="tabpanel" aria-labelledby="documents-tab">
                        <p>Details about required documents go here.</p>
                    </div>
                </div>
            </div>   */}


        </>
    );
}

export default Business_Loan_Apply;












{/* <p>
                    <strong className="business-header-about"> Unlocking Business Potential:</strong> Accessing Business Loans in Chennai
                    Starting a business or expanding an existing one requires capital. At Skanda Fincorp,
                    we understand the financial needs of businesses in Chennai and offer a diverse range
                    of business loans tailored to meet your unique requirements.</p>

                <p>
                    <strong className="business-header-about"> Our Business Loan Offerings in Chennai:</strong>
                </p>

                <p><strong>Working Capital Loans:</strong>
                    Bridge cash flow gaps, manage day-to-day expenses, and ensure smooth operations.
                </p>
                <p> <strong> Term Loans:</strong><br /> Fund long-term investments such as equipment
                    purchases, business expansion, and infrastructure development.</p>
                <p>  <strong>MSME Loans:</strong><br /> Cater specifically to the needs of Micro,
                    Small, and Medium Enterprises with flexible repayment options.</p>
                <p>
                    <strong>Competitive Interest Rates:</strong><br /> Enjoy attractive interest rates
                    that make your loan more affordable.</p>
                <p>
                    <strong>Quick Disbursal:</strong> <br />
                    Receive funds quickly to seize opportunities promptly.</p>
                <p>
                    <strong> Minimal Documentation:</strong> <br />Simplify the application process with
                    minimal paperwork.</p>
                <p>
                    <strong> Flexible Repayment Options:</strong><br /> Choose repayment schedules that
                    align with your cash flow.
                </p>
                <p>
                    <strong>Transparent and Hassle-Free Process:</strong><br /> Experience a smooth and transparent loan application and approval process.
                </p>
                <p><strong> Dedicated Customer Support:</strong><br />
                    Our team of experts is available to guide you through every step of the loan process.
                </p>

                <h6 className="business-header-about">
                    Unlock Best <span className="business-head-bus">Business Loan</span> Offers for
                    meeting your Capital requirements
                </h6>
                <p className="business-hr"></p>
                <small>
                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Best Offers from 10+ Lenders.</small><br />
                <small>
                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Hassle-Free Documentation.</small><br />
                <small>
                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Quick Disbursal.</small><br />
                <h6 className="business-header-about"> Features & Benefits</h6>
                <p className="business-hr"></p>
                <small><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Both secured & unsecured loans are available.</small><br />
                <small><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Loan amount:</strong> Unsecured loan - Up to Rs 1 crore; Secured loans - Up to Rs 5 crore.</small>
                <br />
                <small><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Loan tenure:</strong> Unsecured loan – Up to 5 years; Secured loans - Up to 15 years.</small><br />

                <small>
                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Unsecured loan facilities include term loans & working capital loans.
                </small> <br />
                <small>
                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Secured loan facilities include Cash Credit A/c & Working Capital Loans.
                </small><br />

                <h6 className="business-header-about">Business Loan Eligibility:</h6>
                <p className="business-hr"></p>
                <small><strong><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Age:</strong> 21 - 60 years.</small><br />
                <small><strong><FontAwesomeIcon icon={faCircle} className="business-dot-icon" />  Business Vintage:</strong>1 year & above.</small><br />
                <small><strong><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Credit Score:
                </strong> A credit score of 675 & above increase the chances of loan approval and at lower interest rates.</small><br />
                <h5><strong>Disclaimer:</strong></h5>
                <small>  Skanda Fincorp acts as a loan aggregator, connecting you with our network of trusted lending partners.
                </small> */}
