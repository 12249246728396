import React, { useState, useEffect } from "react";
import Navbar from "../../../component/navbar";
import './index.css';
import Title from "../../../component/title";
import Footer from "../../../component/footer";
import Home_Loan_Image from "../../../component/Loans/Home-loan";
import Home_Loan_Apply from "../../../component/Loans/Home-loan/card";
import Home_Loan_Apply_form from "../../../component/Loans/Home-loan/apply";
import Loading from "../../../component/loading";

function Home_Loan() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Title title="Home Loan | Skanda Fincorp">

        <Navbar />

        <Home_Loan_Image />
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="container mt-4 mb-4">

              <Home_Loan_Apply />

              <Home_Loan_Apply_form />

            </div>

            <Footer />
          </>
        )}
      </Title>
    </>
  )
}

export default Home_Loan;