import React from "react";
import "./index.css";
import Image1 from "../../../Assets/images/about-us.jpg";
import { Link } from "react-router-dom";

function About_Images() {

    return (
        <>
            {/* <div>
                
                <img src={Image1} className="d-block w-100 personal-image" alt="EMI Calculator" />
                <div className="image-text-about">
                    <h1 className="emi-image-head text-white">LOANS</h1>
                    <p className="text-start">
                        <Link className="about-route-home text-dark" to="/">
                            HOME /
                        </Link>
                        <span className="text-white"> About-Us</span>
                    </p>
                </div>
            </div> */}

            <div class="about-banner">
                <div class="container about-banner-content">
                    {/* <p className="text-start">
                        <h1 className=""><strong>MACHINERY LOAN</strong></h1>
                        <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r"> Machinery Loan</span>
                    </p> */}
                </div>
            </div>

        </>
    );
}

export default About_Images;
