import React, { useState, useEffect } from "react";
import Navbar from "../../component/navbar";
import './index.css';
import Title from "../../component/title";
import Footer from "../../component/footer";
import Service_About from "../../component/service";
import MiddleCard from "../../component/home/second card";
import Loading from "../../component/loading";

function Services_Policy() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>

            <Title title="Service | Skanda Fincorp">

                <Navbar />

                <Service_About />
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <MiddleCard />

                        <Footer />
                    </>
                )}
            </Title>

        </>
    )
}

export default Services_Policy;