import React from "react";
import './index.css';
import Image1 from '../../Assets/images/insurance-home3.jpg';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartPulse, faHouseChimneyCrack, faCarBurst, faBedPulse } from '@fortawesome/free-solid-svg-icons';

function Insurance_page_1() {

    return (
        <>
            {/* <div id="carouselExampleFade" className="carousel slide carousel-fade mt-0 position-relative">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={Image1} className="d-block w-100 insurance-image" alt="..." />
                        <div className="image-text-container">
                            <h1 className="about-route-head">Insurance</h1>
                            <p className="text-start">
                                <Link className="about-route-home" to="/">HOME / </Link>
                                <span className="homeloan-page-r"> Insurance</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div class="banner">
                <div class="container banner-content">
                    <p className="text-start">
                    <h1 className="about-route-head text-start">INSURANCE</h1>
                        <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r text-light"> Insurance</span>
                    </p>
                </div>
            </div>

            <div className="container">

                <div className="card text-start border-0 p-5 image-text-container-1 mt-4 mb-4">
                    <h1 className="insurance-card-text mt-0">About Insurance</h1>

                    <p className="text-secondary">Life is uncertain, and it is better to be prepared than suffer. This is the reason
                        behind Insurance policies. Around the world, people are making it a point to get
                        their insurance on time as it is guaranteed protection from any kind of financial
                        loss. Insurance is a form of risk management, which aids us to recover from any
                        unpredicted loss. One can get insured with certain policies where an individual
                        or an entity receives financial protection or an assurance of reimbursement against
                        any financial losses from a reputed insurance company. Some common kinds of insurance
                        include:</p>
                </div>


                <div className="row">
                    <div className="col-md-6">
                        <a class="card1" href="#">
                            <FontAwesomeIcon icon={faHeartPulse} className="home-card-2-icon heart-icon" />
                            <h3 className="insurance-card-text"><strong>Health Insurance</strong></h3>
                            <p class="text-start insurance-card-text">Health insurance is a financial protection against any risk
                                of human health. It covers accidents, death, large/small medical expense,
                                disability, etc. Depending on the known risk or general safety, you can
                                decide your financial structure for this kind of insurance.</p>
                            <div class="go-corner" href="#">
                                <div class="go-arrow">
                                    →
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-6">
                        <a class="card1" href="#">
                            <FontAwesomeIcon icon={faHouseChimneyCrack} className="home-card-2-icon heart-icon" />
                            <h3 className="insurance-card-text"><strong>Home Insurance</strong></h3>
                            <p class="text-start insurance-card-text">Home insurance is provided during unseen accidents
                                causing a significant loss for your residential property.The losses can
                                be theft, accidents or any other incidents which damage some/all of your
                                property.</p>
                            <div class="go-corner" href="#">
                                <div class="go-arrow">
                                    →
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-6">
                        <a class="card1" href="#">
                            <FontAwesomeIcon icon={faCarBurst} className="home-card-2-icon heart-icon" />
                            <h3 className="insurance-card-text"><strong>Car Insurance</strong></h3>
                            <p class="text-start insurance-card-text">From minor repairs to unfortunate
                                incidents, car insurance is specifically made to reimburse any financial loss
                                caused by your car.</p>
                            <div class="go-corner" href="#">
                                <div class="go-arrow">
                                    →
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-6">
                        <a class="card1" href="#">
                            <FontAwesomeIcon icon={faBedPulse} className="home-card-2-icon heart-icon" />
                            <h3 className="insurance-card-text"><strong>Life Insurance</strong></h3>
                            <p class="text-start insurance-card-text">Life insurance is basically provided
                                post the death of a person to the dependant, as mentioned by the insurance
                                policyholder in a legal contract between that person and the insurance company.</p>
                            <div class="go-corner" href="#">
                                <div class="go-arrow">
                                    →
                                </div>
                            </div>
                        </a>
                    </div>
                </div>


            </div>
        </>
    );
}

export default Insurance_page_1;
