import React from "react";
import './index.css';
import Image1 from '../../../Assets/images/business-loan.jpeg';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTentArrowTurnLeft } from '@fortawesome/free-solid-svg-icons';

function Business_Loan_Image() {

    return (
        <>
            {/* <div>
                <img src={Image1} className="d-block w-100 personal-image" alt="..." />

                <div className="image-text-business">
                    <h1 className="about-route-head">Business LOAN</h1>
                    <p className="text-start">
                        <Link className="about-route-home text-white" to="/"> HOME / </Link>
                        <span className="homeloan-page-r"> Business Loan</span>
                    </p>
                </div>
            </div> */}

            <div class="business-banner">
                <div class="container business-banner-content">
                    <p className="text-start banner-content-home">
                        {/* <h1 className="about-route-head text-white">AUTO LOAN</h1> */}
                        <Link className="about-route-home text-white" to="/">HOME / </Link>
                        <span className="homeloan-page-r text-white"> Business Loan</span>
                    </p>
                </div>
            </div>

        </>
    );
}

export default Business_Loan_Image;
