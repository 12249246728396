import React from "react";
import './index.css';
import Image1 from '../../Assets/images/cibil-score-1.png';
import { Link } from "react-router-dom";

function Cibil_About() {

    return (
        <>
            {/* <div>
                <img src={Image1} className="d-block w-100 personal-image" alt="..." />

                <div className="image-text-auto-cibil">
                    <h1 className="about-route-head text-white">CIBIL-SCORE</h1>
                    <p className="text-start">
                        <Link className="about-route-home text-dark" to="/"> HOME / </Link>
                        <span className="homeloan-page-r"> Cibil-Score</span>
                    </p>
                </div>
            </div> */}

            <div class="cibil-banner">
                <div class="container cibil-banner-content">
                    <p className="text-start">
                    {/* <h1 className="about-route-head text-white">CIBIL-SCORE</h1> */}
                        <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r"> Cibil-Score</span>
                    </p>
                </div>
            </div>



            <div className="container">
                <div className="card text-start border-0 p-5 image-text-container-1 mt-4 mb-4">
                    <h1 className="insurance-card-text mt-0">About CIBIL Score</h1>

                    <p className="text-secondary">CIBIL score plays a vital role in understanding the
                        credit risks of an individual. The Credit Information Bureau (India) Ltd or
                        CIBIL is a Reserve Bank of India (RBI) authorized credit agency which offers
                        scores and reports for individuals wishing to get different credits. Banks and
                        NBFCs consider this score or report to decide the creditworthiness of that
                        individual.
                    </p>

                    <p className="text-secondary">The CIBIL score is generated based on the individual’s
                        credit information. With the invention of Artificial Intelligence (AI) and Machine
                        Learning (ML), the accuracy of CIBIL score has also increased. By going through
                        past data of an individual which directly or indirectly provides any information
                        regarding the individual’s credit history, CIBIL score is calculated using an
                        algorithm. While the method remains the same but the calculations differ as there
                        are over 258 different variables included in the algorithm. This is just to ensure
                        precision in the result.
                    </p>

                    <p className="text-secondary">The CIBIL score ranges from 300 to 900, higher the score;
                        greater is the trustworthiness of an individual.
                    </p>
                </div>
            </div>

        </>
    );
}

export default Cibil_About;
