import React, { useState, useEffect } from "react";
import Navbar from "../../component/navbar";
// import './index.css';
import Title from "../../component/title";
import Footer from "../../component/footer";
import Career_Image from "../../component/careers";
import Loading from "../../component/loading";

function Career() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>

            <Title title="EMI-Cal | Skanda Fincorp">

                <Navbar />

                <Career_Image />
                {isLoading ? (
                    <Loading />

                ) : (
                    <>
                        <Footer />
                    </>
                )}
            </Title>
        </>
    )
}

export default Career;