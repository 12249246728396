import React from "react";
import './index.css';
import Image1 from '../../Assets/images/career-2.jpg';
import { Link } from "react-router-dom";

function Career_Image() {

    return (
        <>
            {/* <div>
                
                <img src={Image1} className="d-block w-100 personal-image" alt="EMI Calculator" />
                <div className="image-career-route">
                    <h1 className="emi-image-head text-dark">Careers</h1>
                    <p className="text-start">
                        <Link className="about-route-home text-dark" to="/">
                            HOME /
                        </Link>
                        <span className="homeloan-page-r"> Careers</span>
                    </p>
                </div>
            </div> */}
            <div class="career-banner">
                <div class="container career-banner-content">
                    {/* <p className="text-start">
                        <h1 className=""><strong>MACHINERY LOAN</strong></h1>
                        <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r"> Machinery Loan</span>
                    </p> */}
                </div>
            </div>

            <div className="container">
                <div className="card p-5 mb-4 mt-4 border-0 form-card">
                    <h2><small>Apply Now</small></h2>
                    <p className="form-content mt-4">
                        At Skanda-Fincorp, we believe that our plans for the future can be achieved only through
                        dedicated, focused and innovative work and our employees are the driving force for achieving
                        our ambitions. We offer our employees a congenial environment to work that will bring the
                        best out of every employee.
                    </p>
                    <form className="apply-form">
                        <div className="form-row">
                            <input type="text" className="form-control" placeholder="Name" required />
                            <input type="email" className="form-control" placeholder="Email" required />
                            <input type="tel" className="form-control" placeholder="Phone" required />
                        </div>

                        <div class="mb-3 mt-4">
                            <input class="form-control" type="file" id="imageUpload" accept="image/*" />
                        </div>
                        <div className="form-row mt-4">
                            <textarea className="form-control message-box" placeholder="Message" rows="5" required></textarea>
                        </div>
                        <button type="submit" className="submit-button mt-4">SUBMIT</button>
                    </form>
                </div>
            </div>

        </>
    );
}

export default Career_Image;
