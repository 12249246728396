import React from "react";
import './index.css';
import Image1 from '../../../Assets/images/gold-loan.jpg';
import { Link } from "react-router-dom";

function Gold_Loan_Image() {

    return (
        <>
            {/* <div>
                <img src={Image1} className="d-block w-100 personal-image" alt="..." />

                <div className="image-text-home">
                    <h1 className="about-route-head">GOLD LOAN</h1>
                    <p className="text-start">
                        <Link className="about-route-home" to="/"> HOME / </Link>
                        <span className="homeloan-page-r"> Gold Loan</span>
                    </p>
                </div>
            </div> */}

            <div class="gold-banner">
                <div class="container gold-banner-content">
                    <p className="text-start">
                        <h1 className=""><strong>GOLD LOAN</strong></h1>
                        <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r"> Gold Loan</span>
                    </p>
                </div>
            </div>

        </>
    );
}

export default Gold_Loan_Image;
