import React, { useRef, useState } from "react";
import './index.css';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Image2 from '../../../Assets/images/business-loan.jpeg';
import Image3 from '../../../Assets/images/odcc-2-img.jpg';
import Image4 from '../../../Assets/images/privatecheque.jpg';
import Image5 from '../../../Assets/images/ODCC-img.avif';
import Image6 from '../../../Assets/images/property-loan1.jpg';
import Image7 from '../../../Assets/images/car-loan-12.jpg';
import Image8 from '../../../Assets/images/machinary-loan.jpg';
import Image9 from '../../../Assets/images/gold-loan.jpg';
function PersonalAbout() {

    const [activeStep, setActiveStep] = useState(1);

    const handleStepClick = (step) => {
        setActiveStep(step);
    };

    const [activeTab, setActiveTab] = useState("credit");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const containerRef = useRef(null);
    const containerRef1 = useRef(null);
    const totalSteps = 6;
    const visibleSteps = 3;
    const stepWidth = 300; 
    const maxScroll = (totalSteps - visibleSteps) * stepWidth;

    const handleScroll = (direction) => {
        if (containerRef.current) {
            const stepWidth = 300; 
            const currentScroll = containerRef.current.scrollLeft;

            const scrollAmount = direction === 'next' ? stepWidth : -stepWidth;
            const newScrollPosition = currentScroll + scrollAmount;

            if (newScrollPosition >= 0 && newScrollPosition <= maxScroll) {
                containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            }
        }
    };


    const handleScroll1 = (direction) => {
        if (containerRef1.current) {
            const scrollAmount = direction === "next" ? 300 : -300; 
            containerRef1.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
        }
    };

    return (
        <>
            <div className="card text-start p-3 border-0 personal-about-card">

                <h2 className="personal-card-header">About Personal Loan</h2>

                <p className="personal-card-para">Want to order that premium buffet for your wedding but budget isn’t supporting?
                    Or upset about cash scarcity for your career plan abroad? Or need financial
                    assistance for that urgent surgery? Then you have come to the right place.</p>

                <p className="personal-card-para">We, at Skanda Fincorp, understand the importance of each stage in your life and are
                    humbled to make an impact. Our motto is to help you with an honest intention.</p>
                <p className="personal-card-para">Personal Loan is a short/medium tenured loan which requires no collateral/security
                    for validation. The time taken to disburse these loans varies from a few hours
                    to few days with minimal/no paperwork. Personal loans are specifically
                    considered for its flexible end-use feature. These loans can be used for
                    various purposes including emergency medical expenses, home renovation,
                    wedding, etc.</p>
            </div>
            
            <div className="card p-3 text-start border-0 personal-about-card">

                <div className="row">
                    <div className="col-md-12 d-flex">
                        <button
                            className="carousel-btn prev"
                            onClick={() => handleScroll("prev")}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <ul className="business-card-ul" ref={containerRef}>
                            <li
                                className={`step ${activeStep === 1 ? "active" : ""}`}
                                onClick={() => handleStepClick(1)}
                            >
                                <span className="step-title">Benefits</span>
                            </li>
                            <li
                                className={`step ${activeStep === 2 ? "active" : ""}`}
                                onClick={() => handleStepClick(2)}
                            >
                                <span className="step-title">Features</span>
                            </li>
                            <li
                                className={`step ${activeStep === 5 ? "active" : ""}`}
                                onClick={() => handleStepClick(5)}
                            >
                                <span className="step-title">Personal Loan Eligibility</span>
                            </li>
                            <li
                                className={`step ${activeStep === 6 ? "active" : ""}`}
                                onClick={() => handleStepClick(6)}
                            >
                                <span className="step-title">Disclaimer</span>
                            </li>

                        </ul>
                        <button
                            className="carousel-btn next"
                            onClick={() => handleScroll("next")}
                            disabled={containerRef.current?.scrollLeft >= (totalSteps - visibleSteps) * stepWidth}
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                    <div className="col-md-12 card p-4 mt-4 rounded-0">
                        
                        {activeStep === 1 && (
                            <div className="text-start">
                                <h6 className="business-header-about"> Benefits</h6>
                                <p className="business-hr"></p>
                               <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> No end-usage restriction.</p>
                               <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Loan amount of up to Rs. 40 lakh, which can exceed depending on lenders' discretion.</p>
                               <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Repayment tenure usually ranges between 1 year and 5 years, with some banks/NBFCs offering longer tenures.</p>
                               <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Minimal documentation.</p>
                               <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> The interest rates usually start from 10.50% p.a. with some PSU banks offering lower interest rates.</p>
                               <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Quick disbursals.</p>
                               <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Applicants having excellent credit profiles may also qualify for preapproved/prequalified personal loans with instant disbursal.</p>
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div className="text-start">
                                <h6 className="business-header-about"> Features</h6>
                                <p className="business-hr"></p>
                               <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> No end-usage restriction.</p>
                               <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Loan amount of up to Rs. 40 lakh, which can exceed depending on lenders' discretion.</p>
                               <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Repayment tenure usually ranges between 1 year and 5 years, with some banks/NBFCs offering longer tenures.</p>
                               <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Minimal documentation.</p>
                               <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> The interest rates usually start from 10.50% p.a. with some PSU banks offering lower interest rates.</p>
                               <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Quick disbursals.</p>
                               <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Applicants having excellent credit profiles may also qualify for preapproved/prequalified personal loans with instant disbursal.</p>
                            </div>
                        )}
                        {activeStep === 5 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Personal Loan Eligibility</h6>
                                <p className="business-hr"></p>
                                <p><strong><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Age:</strong> 18 - 60 years.</p>
                                <p><strong><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Income:</strong> Minimum Rs 15,000/month for salaried applicants.</p>
                                <p><strong><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Credit Score:
                                </strong> Preferably 750 and above as having higher credit scores increase the chances of your loan approval at lower interest rates.</p>
                            </div>
                        )}
                        {activeStep === 6 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Disclaimer</h6>
                                <p className="business-hr"></p>
                                <p className="m-0 p-0">  Skanda Fincorp is a loan aggregator and is authorized to provide services on behalf of its partners.
                                </p><br />
                                <p>
                                    The APR (Annual Percentage Rate) of a personal loan is its annualised cost of borrowing, which includes the interest rate as well as the processing fees, documentation fees and other fees charged during the loan origination. The APR is expressed in the form of a percentage and thus, allows personal loan applicants to detect personal loan schemes offered at lower interest rates but with higher processing fees and/or other charges.
                                </p>
                                <p>
                                    The APR of personal loans usually ranges between 11.29% to 35%. For example, assume that you have availed a personal loan of Rs. 5 lakhs @ 10.50% p.a. with a repayment tenure of 5 years. The processing fee charged for this loan is 1.5% of the loan amount, which amounts to Rs 7,500. Therefore, the total borrowing cost of your personal loan will be Rs 1,52,317 and its APR will be 11.16%.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div >

            <div className="carousel-container">
                <button
                    className="carousel-btn1 prev"
                    onClick={() => handleScroll1("prev")}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <div className="carousel-wrapper d-flex" ref={containerRef1}>
                    <div className="product-card">
                        <img src={Image2} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Business Loan</p>
                        <Link to={'/business-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image3} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">OD & CC Loan</p>
                        <Link to={'/ODCC-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image4} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Private Cheque Loan</p>
                        <Link to={'/PrivateCheque-Loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image5} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Home Loan</p>
                        <Link to={'/home-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image6} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Loan Against Property</p>
                        <Link to={'/property-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image7} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Auto Loan</p>
                        <Link to={'/auto-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image8} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Machinery Loan</p>
                        <Link to={'/machinery-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image9} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Gold Loan</p>
                        <Link to={'/gold-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                </div>
                <button
                    className="carousel-btn1 next"
                    onClick={() => handleScroll1("next")}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
            </div>

        </>
    );
}

export default PersonalAbout;
