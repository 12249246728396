import React from "react";
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSheetPlastic, faUserGroup, faPhone } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from "react-router-dom";

function Pricing_card() {

    return (
        <>
            <div className="container">
                <h3>We are Here to Help You</h3>
                <p className="text-secondary">Our mission is to deliver reliable, latest news and opinions.</p>
                <div className="row gap-5 pricing-row">
                    <div className="col-md-3 pricing-card card p-5 border-0 ">
                        <FontAwesomeIcon icon={faSheetPlastic} className="home-card-2-icon mt-2" />
                        <p className="mt-4"><strong>APPLY FOR LOAN</strong></p>
                        <p className="pricing-content mt-3">Looking to buy a car or home on a loan? then apply for a loan now.</p>
                        <button className="home-last-btn">
                            <NavLink
                                className="nav-link nav-home"
                                to="/contact-us"
                                activeClassName="active" >
                                Get Appointment
                            </NavLink>
                        </button>
                    </div>

                    <div className="col-md-3 pricing-card card p-5 border-0 ">
                        <FontAwesomeIcon icon={faPhone} className="home-card-2-icon mt-2" />
                        <p className="mt-4"><strong>CALL US AT</strong></p>
                        <p className="pricing-content text-success mt-3">
                            <strong>+91 7358515353</strong>
                        </p>
                        <p className="pricing-content">
                            <a href="mailto:pandian@skandafincorp.com" className="gmail-link-gopage pricing-content">
                                {/* peoplemindpandian@gmail.com<br /> */}
                                pandian@skandafincorp.com
                            </a>
                        </p>
                        <button className="home-last-btn">
                            <NavLink
                                className="nav-link nav-home"
                                to="/contact-us"
                                activeClassName="active" >
                                contact us
                            </NavLink>
                        </button>
                    </div>

                    <div className="col-md-3 pricing-card card p-5 border-0 ">
                        <FontAwesomeIcon icon={faUserGroup} className="home-card-2-icon mt-2" />
                        <p className="mt-4"><strong>TALK TO ADVISOR</strong></p>
                        <p className="pricing-content mt-3">Need to loan advise? Talk to our Loan advisors.</p>
                        <p className="home-last-btn">
                            <NavLink
                                className="nav-link nav-home"
                                to="/contact-us"
                                activeClassName="active" >
                                Meet The Advisor
                            </NavLink>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pricing_card;