import React, { useState, useEffect } from "react";
import Navbar from "../../../component/navbar";
// import './index.css';
import Title from "../../../component/title";
import Footer from "../../../component/footer";
import Property_Loan_Image from "../../../component/Loans/Property-Loan";
import Property_Loan_Apply from "../../../component/Loans/Property-Loan/apply";
import Loading from "../../../component/loading";

function Property_Loan() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>

            <Title title="Property Loan | Skanda Fincorp">

                <Navbar />

                <Property_Loan_Image />
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="container mt-4 mb-4">

                            <Property_Loan_Apply />

                        </div>

                        <Footer />
                    </>
                )}
            </Title>
        </>
    )
}

export default Property_Loan;