import React, { useState, useEffect } from "react";
import Navbar from "../../component/navbar";
import './index.css';
import Title from "../../component/title";
import { Link } from "react-router-dom";
import Footer from "../../component/footer";
import Contact_Call from "../../component/contact-us/call-base";
import Contact_Image from "../../component/contact-us";
import Contact_ApplyForm from "../../component/contact-us/apply";
import Loading from "../../component/loading";

function ContactUs() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>

            <Title title="Skanda Fincorp :: Contact-US">
                <Navbar />

                <Contact_Image />
                {isLoading ? (
                    <Loading />

                ) : (
                    <>
                        <div className="container mt-4">
                            <Contact_Call />

                            <Contact_ApplyForm />
                        </div>

                        <Footer />
                    </>
                )}
            </Title>

        </>
    )
}

export default ContactUs;