import React, { useRef, useState } from "react";
import './index.css';
import Image1 from '../../../Assets/images/home-loan1.jpg';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Image2 from '../../../Assets/images/business-loan.jpeg';
import Image3 from '../../../Assets/images/odcc-2-img.jpg';
import Image4 from '../../../Assets/images/privatecheque.jpg';
import Image5 from '../../../Assets/images/Personal-Loan.jpg';
import Image6 from '../../../Assets/images/ODCC-img.avif';
import Image7 from '../../../Assets/images/car-loan-12.jpg';
import Image8 from '../../../Assets/images/machinary-loan.jpg';
import Image9 from '../../../Assets/images/gold-loan.jpg';

function Property_Loan_Apply() {

    const [activeStep, setActiveStep] = useState(1);

    const handleStepClick = (step) => {
        setActiveStep(step);
    };

    const [activeTab, setActiveTab] = useState("credit");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const containerRef = useRef(null);
    const containerRef1 = useRef(null);
    const totalSteps = 6; 
    const visibleSteps = 3;
    const stepWidth = 300; 
    const maxScroll = (totalSteps - visibleSteps) * stepWidth;

    const handleScroll = (direction) => {
        if (containerRef.current) {
            const stepWidth = 300;
            const currentScroll = containerRef.current.scrollLeft;

            const scrollAmount = direction === 'next' ? stepWidth : -stepWidth;
            const newScrollPosition = currentScroll + scrollAmount;

            if (newScrollPosition >= 0 && newScrollPosition <= maxScroll) {
                containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            }
        }
    };


    const handleScroll1 = (direction) => {
        if (containerRef1.current) {
            const scrollAmount = direction === "next" ? 300 : -300; 
            containerRef1.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
        }
    };

    return (
        <>

            <div className="card p-3 text-start border-0 personal-about-card">

                <h2 className="personal-card-header">Loan against property</h2>
                <p className="personal-card-para">
                    Facing a financial crunch? Need funds for business expansion, home renovation,
                    medical emergencies, or any other major life event? Skanda Fincorp offers you
                    a convenient and hassle-free solution: <strong>Loan Against Property (LAP)</strong>.
                </p>
            </div>

            <div className="card p-3 text-start border-0 personal-about-card">

                <div className="row">
                    <div className="col-md-12 d-flex">
                        <button
                            className="carousel-btn prev"
                            onClick={() => handleScroll("prev")}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <ul className="business-card-ul" ref={containerRef}>
                            <li
                                className={`step ${activeStep === 1 ? "active" : ""}`}
                                onClick={() => handleStepClick(1)}
                            >
                                 <span className="step-title">Benefits</span>
                            </li>
                            <li
                                className={`step ${activeStep === 2 ? "active" : ""}`}
                                onClick={() => handleStepClick(2)}
                            >
                                 <span className="step-title">Features</span>
                            </li>
                            <li
                                className={`step ${activeStep === 5 ? "active" : ""}`}
                                onClick={() => handleStepClick(5)}
                            >
                                <span className="step-title">Loan against property Eligibility</span>
                            </li>
                            <li
                                className={`step ${activeStep === 6 ? "active" : ""}`}
                                onClick={() => handleStepClick(6)}
                            >
                                <span className="step-title">Disclaimer</span>
                            </li>

                        </ul>
                        <button
                            className="carousel-btn next"
                            onClick={() => handleScroll("next")}
                            disabled={containerRef.current?.scrollLeft >= (totalSteps - visibleSteps) * stepWidth}
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                    <div className="col-md-12 card p-4 mt-4 rounded-0">
                      
                        {activeStep === 1 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Benefits</h6>
                                <p className="business-hr"></p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Immediate Access to Funds: </strong>Receive quick disbursal of funds
                                    to meet your urgent financial needs.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Low EMIs: </strong>Enjoy affordable monthly repayments that fit comfortably within your budget.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Fast and Easy Processing: </strong>Experience a streamlined application process with minimal documentation.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Speedy Approvals: </strong>Get quick loan approvals to minimize waiting time.
                                </p>
                                {/* <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Flexible Tenure Options: </strong>Choose a repayment tenure that best suits your financial circumstances.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Simplified Documentation: </strong>Minimize paperwork and enjoy a hassle-free loan application process.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Easy Balance Transfer: </strong>Seamlessly transfer existing loans from other lenders to Skanda Fincorp at competitive rates.
                                </p> */}
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div className="text-start">
                                <h6 className="business-header-about"> Features</h6>
                                <p className="business-hr"></p>
                                {/* <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Immediate Access to Funds: </strong>Receive quick disbursal of funds
                                    to meet your urgent financial needs.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Low EMIs: </strong>Enjoy affordable monthly repayments that fit comfortably within your budget.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Fast and Easy Processing: </strong>Experience a streamlined application process with minimal documentation.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Speedy Approvals: </strong>Get quick loan approvals to minimize waiting time.
                                </p> */}
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Flexible Tenure Options: </strong>Choose a repayment tenure that best suits your financial circumstances.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Simplified Documentation: </strong>Minimize paperwork and enjoy a hassle-free loan application process.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Easy Balance Transfer: </strong>Seamlessly transfer existing loans from other lenders to Skanda Fincorp at competitive rates.
                                </p>
                            </div>
                        )}
                        {activeStep === 5 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Loan against property Eligibility</h6>
                                <p className="business-hr"></p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Available for both salaried and self-employed individuals.</p>
                                <p>
                                    <h6><strong>Experience the Skanda Fincorp Advantage:</strong></h6>
                                    <p>
                                        At Skanda Fincorp, we understand your financial needs and strive to provide you with the best possible solutions.
                                        Our LAP offers a convenient and reliable way to leverage your property's value to achieve your financial goals.
                                    </p>
                                    <p>
                                        Contact us today to learn more about our LAP offerings and explore how we can help you
                                        unlock the potential of your property.
                                    </p>
                                </p>
                            </div>
                        )}
                        {activeStep === 6 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Disclaimer</h6>
                                <p className="business-hr"></p>
                                <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> This information is for general guidance only and should not be considered financial advice.</p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Please consult with a financial professional for personalized recommendations. </p>
                            </div>
                        )}
                    </div>
                </div>
            </div >

            <div className="carousel-container">
                <button
                    className="carousel-btn1 prev"
                    onClick={() => handleScroll1("prev")}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <div className="carousel-wrapper d-flex" ref={containerRef1}>
                    <div className="product-card">
                        <img src={Image2} alt="business loan" className='product-card-img'/>
                        <p className="text-truncate">Business Loan</p>
                        <Link to={'/business-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image3} alt="business loan" className='product-card-img'/>
                        <p className="text-truncate">OD & CC Loan</p>
                        <Link to={'/ODCC-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image4} alt="business loan" className='product-card-img'/>
                        <p className="text-truncate">Private Cheque Loan</p>
                        <Link to={'/PrivateCheque-Loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image5} alt="business loan" className='product-card-img'/>
                        <p className="text-truncate">Personal Loan</p>
                        <Link to={'/personal-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image6} alt="business loan" className='product-card-img'/>
                        <p className="text-truncate">Home Loan</p>
                        <Link to={'/home-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image7} alt="business loan" className='product-card-img'/>
                        <p className="text-truncate">Auto Loan</p>
                        <Link to={'/auto-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image8} alt="business loan" className='product-card-img'/>
                        <p className="text-truncate">Machinery Loan</p>
                        <Link to={'/machinery-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image9} alt="business loan" className='product-card-img'/>
                        <p className="text-truncate">Gold Loan</p>
                        <Link to={'/gold-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                </div>
                <button
                    className="carousel-btn1 next"
                    onClick={() => handleScroll1("next")}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
            </div>
            <div class="form-container mt-4 mb-4">
                <div className="card p-5 border-0 form-card">
                    <h2 class="form-header-con">
                        Apply Now!
                    </h2>
                    <p className="text-secondary">Now apply for a Loan against property online,
                        All you need to do is provide your details below application form.</p>
                    <form action="#" >
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" >Name</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">E-Mail</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">Place</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">Phone</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" className="form-name">Loan Amount</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" className="form-name-1">Year</label>
                            </div>
                        </div>
                        <button className="form-btn-page mt-4">Apply Now</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Property_Loan_Apply;
