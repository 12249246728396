import React, { useRef, useState } from "react";
import './index.css';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Image2 from '../../../Assets/images/business-loan.jpeg';
import Image3 from '../../../Assets/images/privatecheque.jpg';
import Image4 from '../../../Assets/images/Personal-Loan.jpg';
import Image5 from '../../../Assets/images/ODCC-img.avif';
import Image6 from '../../../Assets/images/property-loan1.jpg';
import Image7 from '../../../Assets/images/car-loan-12.jpg';
import Image8 from '../../../Assets/images/machinary-loan.jpg';
import Image9 from '../../../Assets/images/gold-loan.jpg';
function Education_Loan_Apply() {
    const [activeStep, setActiveStep] = useState(1);

    const handleStepClick = (step) => {
        setActiveStep(step);
    };

    const [activeTab, setActiveTab] = useState("credit");


    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const containerRef = useRef(null);
    const containerRef1 = useRef(null);
    const totalSteps = 6; 
    const visibleSteps = 3; 
    const stepWidth = 300;
    const maxScroll = (totalSteps - visibleSteps) * stepWidth;

    const handleScroll = (direction) => {
        if (containerRef.current) {
            const stepWidth = 300; 
            const currentScroll = containerRef.current.scrollLeft;

            const scrollAmount = direction === 'next' ? stepWidth : -stepWidth;
            const newScrollPosition = currentScroll + scrollAmount;

            if (newScrollPosition >= 0 && newScrollPosition <= maxScroll) {
                containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            }
        }
    };


    const handleScroll1 = (direction) => {
        if (containerRef1.current) {
            const scrollAmount = direction === "next" ? 300 : -300;
            containerRef1.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
        }
    };
    return (
        <>

            <div className="card p-3 text-start border-0 personal-about-card">

                <h2 className="personal-card-header">OD&CC Loan</h2>

                <p className="personal-card-para">
                    In today's dynamic business environment, securing the necessary capital is crucial for growth and
                    success. While both cash credit and overdraft facilities offer short-term funding, understanding
                    their nuances is essential to choose the best option for your specific needs.
                </p>

                <p><FontAwesomeIcon icon={faArrowRight} color="gray" /> <strong>Cash Credit:</strong> This is a revolving credit line extended
                 to businesses to meet working capital requirements. You can draw funds as needed within <br /> 
                 the sanctioned limit and repay the utilized amount.</p>
                <p><FontAwesomeIcon icon={faArrowRight} color="gray" /> <strong>Overdraft: </strong>This allows businesses to overdraw their 
                bank account temporarily, providing immediate access to funds when needed.</p>

            </div>
            <div className="card p-3 text-start border-0 personal-about-card">

                <div className="row">
                    <div className="col-md-12 d-flex">
                        <button
                            className="carousel-btn prev"
                            onClick={() => handleScroll("prev")}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <ul className="business-card-ul" ref={containerRef}>
                            <li
                                className={`step ${activeStep === 1 ? "active" : ""}`}
                                onClick={() => handleStepClick(1)}
                            >
                                <span className="step-title"> Benefits</span>
                            </li>
                            <li
                                className={`step ${activeStep === 2 ? "active" : ""}`}
                                onClick={() => handleStepClick(2)}
                            >
                                <span className="step-title">Features</span>
                            </li>
                            <li
                                className={`step ${activeStep === 5 ? "active" : ""}`}
                                onClick={() => handleStepClick(5)}
                            >
                                <span className="step-title">OD & CC Loan Eligibility</span>
                            </li>
                            <li
                                className={`step ${activeStep === 6 ? "active" : ""}`}
                                onClick={() => handleStepClick(6)}
                            >
                                <span className="step-title">Disclaimer</span>
                            </li>

                        </ul>
                        <button
                            className="carousel-btn next"
                            onClick={() => handleScroll("next")}
                            disabled={containerRef.current?.scrollLeft >= (totalSteps - visibleSteps) * stepWidth}
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                    <div className="col-md-12 card p-4 mt-4 rounded-0">
                        {activeStep === 1 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Benefits</h6>
                                <p className="business-hr"></p>
                                <table class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Benefits</th>
                                            <th scope="col">Cash Credit</th>
                                            <th scope="col">Overdraft</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <tr>
                                            <td>Interest Rate</td>
                                            <td>Generally lower</td>
                                            <td colspan="2">Typically higher</td>

                                        </tr>
                                        <tr>
                                            <td>Security</td>
                                            <td>Usually requires collateral (inventory, stock)</td>
                                            <td>May require collateral or be based on credit history, financials</td>
                                        </tr>
                                        <tr>
                                            <td>Purpose</td>
                                            <td>Primarily for business working capital (inventory, raw materials, wages)</td>
                                            <td>Can be used for various business expenses</td>
                                        </tr>
                                        <tr>
                                            <td>Loan Amount</td>
                                            <td>Based on collateral value</td>
                                            <td>Based on creditworthiness and financial performance</td>

                                        </tr> */}
                                        <tr>
                                            <td>Limit</td>
                                            <td>Remains constant</td>
                                            <td>May fluctuate based on usage and repayment</td>

                                        </tr>
                                        <tr>
                                            <td>Account</td>
                                            <td>Requires a separate credit account</td>
                                            <td>Available on existing bank accounts</td>

                                        </tr>
                                        <tr>
                                            <td>Tenure</td>
                                            <td>Longer term (typically 1 year or more)</td>
                                            <td>Shorter term (days, weeks, or months)</td>
                                        </tr>
                                        <tr>
                                            <td>Application</td>
                                            <td>More rigorous application process</td>
                                            <td>Relatively easier to apply</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div className="text-start">
                                <h6 className="business-header-about"> Features</h6>
                                <p className="business-hr"></p>
                                <table class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Feature	</th>
                                            <th scope="col">Cash Credit</th>
                                            <th scope="col">Overdraft</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Interest Rate</td>
                                            <td>Generally lower</td>
                                            <td colspan="2">Typically higher</td>

                                        </tr>
                                        <tr>
                                            <td>Security</td>
                                            <td>Usually requires collateral (inventory, stock)</td>
                                            <td>May require collateral or be based on credit history, financials</td>
                                        </tr>
                                        <tr>
                                            <td>Purpose</td>
                                            <td>Primarily for business working capital (inventory, raw materials, wages)</td>
                                            <td>Can be used for various business expenses</td>
                                        </tr>
                                        <tr>
                                            <td>Loan Amount</td>
                                            <td>Based on collateral value</td>
                                            <td>Based on creditworthiness and financial performance</td>

                                        </tr>
                                        {/* <tr>
                                            <td>Limit</td>
                                            <td>Remains constant</td>
                                            <td>May fluctuate based on usage and repayment</td>

                                        </tr>
                                        <tr>
                                            <td>Account</td>
                                            <td>Requires a separate credit account</td>
                                            <td>Available on existing bank accounts</td>

                                        </tr>
                                        <tr>
                                            <td>Tenure</td>
                                            <td>Longer term (typically 1 year or more)</td>
                                            <td>Shorter term (days, weeks, or months)</td>
                                        </tr>
                                        <tr>
                                            <td>Application</td>
                                            <td>More rigorous application process</td>
                                            <td>Relatively easier to apply</td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {activeStep === 5 && (
                            <div className="text-start">
                                <h6 className="business-header-about">OD & CC Loan Eligibility</h6>
                                <p className="business-hr"></p>
                                <p>
                                    <strong>Interest Rates:</strong> Compare interest rates offered by different lenders.</p>
                                <p><strong>Fees:</strong> Inquire about processing fees, maintenance charges, and any other applicable fees.
                                </p>
                                <p><strong>Repayment Terms:
                                </strong> Understand the repayment schedule and any penalties for late payments.</p>
                                <p>
                                    <strong>Collateral Requirements:</strong> Determine the collateral requirements for each option and their impact on your business operations.
                                </p>
                                <p>
                                    <strong>Loan Limits:</strong> Evaluate the loan limits offered by different lenders and ensure they align with your business needs.
                                </p>
                            </div>
                        )}
                        {activeStep === 6 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Disclaimer</h6>
                                <p className="business-hr"></p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> This information is for general guidance only and does not constitute financial advice.
                                    Please consult with a financial advisor for personalized recommendations.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> By carefully considering these factors and understanding the specific needs of your business,
                                    you can make an informed decision about the most suitable financing option for your growth journey.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div >

            <div className="carousel-container">
                <button
                    className="carousel-btn1 prev"
                    onClick={() => handleScroll1("prev")}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <div className="carousel-wrapper d-flex" ref={containerRef1}>
                    <div className="product-card">
                        <img src={Image2} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Business Loan</p>
                        <Link to={'/business-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image3} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Private Cheque Loan</p>
                        <Link to={'/PrivateCheque-Loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image4} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Personal Loan</p>
                        <Link to={'/personal-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image5} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Home Loan</p>
                        <Link to={'/home-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image6} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Loan Against Property</p>
                        <Link to={'/property-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image7} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Auto Loan</p>
                        <Link to={'/auto-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image8} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Machinery Loan</p>
                        <Link to={'/machinery-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image9} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Gold Loan</p>
                        <Link to={'/gold-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                </div>
                <button
                    className="carousel-btn1 next"
                    onClick={() => handleScroll1("next")}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
            </div>

            <div class="form-container mt-4 mb-4">
                <div className="card p-5 border-0 form-card">
                    <h2 class="form-header-con">
                        Apply Now
                    </h2>
                    <p className="text-secondary">Now apply for an Education Loan online, All you
                        need to do is provide your details below application form.</p>
                    <form action="#" >
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" >Name</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">E-Mail</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">Place</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">Phone</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" className="form-name">Loan Amount</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" className="form-name-1">Year</label>
                            </div>
                        </div>
                        <button className="form-btn-page mt-4">Apply Now</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Education_Loan_Apply;
