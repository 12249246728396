import React, { useState, useEffect } from "react";
import Navbar from "../../component/navbar";
// import './index.css';
import Title from "../../component/title";
import Footer from "../../component/footer";
import Invesment_About from "../../component/invesment";
import Invesment_ApplyForm from "../../component/invesment/apply";
import Loading from "../../component/loading";

function Invesment() {
   const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>

                <Title title="Invesment | Skanda Fincorp">

                    <Navbar />

                    <Invesment_About />
                    {isLoading ? (
                    <Loading />

                ) : (
                    <>

                    <div className="container">
                        <Invesment_ApplyForm />
                    </div>

                    <Footer />
                    </>
                )}
                </Title>
    
        </>
    )
}

export default Invesment;