import React, { useState, useEffect } from "react";
import Navbar from "../../component/navbar";
import './index.css';
import Title from "../../component/title";
import Footer from "../../component/footer";
import Insurance_page_1 from "../../component/Insurance";
import ApplyForm from "../../component/apply-form";
import Loading from "../../component/loading";

function Insurance() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>

            <Title title="Insurance | Skanda Fincorp">

                <Navbar />
                <Insurance_page_1 />

                {isLoading ? (
                    <Loading />

                ) : (
                    <>
                        <div className="container">

                            <ApplyForm />

                        </div>
                        <Footer />
                    </>
                )}
            </Title>

        </>
    )
}

export default Insurance;