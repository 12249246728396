import React from "react";
import "./index.css";
import Image1 from "../../Assets/images/loan.jpg";
import { Link } from "react-router-dom";

function Service_About() {

    return (
        <>
            {/* <div>
            
                <img src={Image1} className="d-block w-100 personal-image" alt="EMI Calculator" />
                <div className="image-text-auto-cibil">
                    <h1 className="emi-image-head text-white">LOANS</h1>
                    <p className="text-start">
                        <Link className="about-route-home text-white" to="/">
                            HOME /
                        </Link>
                        <span className="homeloan-page-r"> Services</span>
                    </p>
                </div>
            </div> */}

            <div class="service-banner">
                <div class="container service-banner-content">
                    {/* <p className="text-start">
                        <h1 className="text-light"><strong>SERVICES</strong></h1>
                        <Link className="about-route-home text-light" to="/">HOME / </Link>
                        <span className="homeloan-page-r text-light"> Service</span>
                    </p> */}
                </div>
            </div>

        </>
    );
}

export default Service_About;
