import React from "react";
import './index.css';
import Image1 from '../../../Assets/images/Personal-Loan.jpg';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartPulse, faHouseChimneyCrack, faCarBurst, faBedPulse } from '@fortawesome/free-solid-svg-icons';

function PersonalLoan() {

    return (
        <>
            {/* <div>
                <img src={Image1} className="d-block w-100 personal-image" alt="..." />

                <div className="container image-text-personal">
                    <h1 className="about-route-head">Personal Loan</h1>
                    <p className="text-start">
                        <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r"> Personal Loan</span>
                    </p>
                </div>
            </div> */}

            <div class="personal-banner">
                <div class="container personal-banner-content">
                    <p className="text-start">
                        {/* <h1 className=""><strong>PERSONAL LOAN</strong></h1> */}
                        {/* <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r"> Personal Loan</span> */}
                    </p>
                </div>
            </div>
        </>
    );
}

export default PersonalLoan;
