import React from "react";
import './index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";

function Contact_Call() {

    return (
        <>
            <div className="call-base-container">
                <div className="text-start">
                    <h4 className="call-base-header">We are here to help you</h4>
                    <p className="text-secondary">Contact our sales team Our team is happy to answer your sales questions.
                        Fill out the form and we’ll be in touch as soon as possible.</p>

                </div>
                <div className="row mb-4 contact-box mt-4">
                    <div className="col-md-4 contact-main-side">
                        <FontAwesomeIcon icon={faPhone} className="contact-icon" />
                        <h4 className="contact_number mt-4">+91 7358515353</h4>
                    </div>
                    <div className="col-md-4 contact-main1-side">
                        <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
                        <a href="mailto:pandian@skandafincorp.com" className="gmail-link-gopage">
                            <h4 className="contact_number mt-4"> peoplemindpandian@gmail.com<br />pandian@skandafincorp.com</h4>
                        </a>
                    </div>
                    <div className="col-md-4 contact-main2-side">
                        <FontAwesomeIcon icon={faMapLocationDot} className="contact-icon" />
                        <h4 className="contact_number mt-4"> No:10, Perumal Koil street, Mananchery,<br /> Kundrathur, Chennai-600069</h4>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Contact_Call;