import React, { useState, useEffect } from "react";
import Navbar from "../../../component/navbar";
// import './index.css';
import Title from "../../../component/title";
import Footer from "../../../component/footer";
import Auto_Loan_Image from "../../../component/Loans/Auto-Loan";
import Auto_Loan_Apply from "../../../component/Loans/Auto-Loan/apply";
import Loading from "../../../component/loading";

function Auto_Loan() {
  const [isLoading, setIsLoading] = useState(true);
  
      useEffect(() => {
          const timeout = setTimeout(() => {
              setIsLoading(false);
          }, 500);
  
          return () => clearTimeout(timeout);
      }, []);

    return (
        <>

            <Title title="Auto Loan | Skanda Fincorp">

                <Navbar />

                <Auto_Loan_Image />
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                <div className="container mt-4 mb-4">

                    <Auto_Loan_Apply />
                    
                </div>

                <Footer />
                </>
                )}
            </Title>
        
        </>
    )
}

export default Auto_Loan;