import React, { useState, useEffect } from "react";
import "./index.css";
import Image1 from "../../Assets/images/EMI-calculator-1.jpg";
import { Link } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

function Emi_cal() {
    // State Variables
    const [loanAmount, setLoanAmount] = useState(1880000);
    const [interestRate, setInterestRate] = useState(2.1);
    const [loanTenure, setLoanTenure] = useState(0.5);
    const [emiDetails, setEmiDetails] = useState({
        totalPayment: 0,
        emi: 0,
        principal: 0,
        interest: 0,
    });

    // EMI Calculation Logic
    const calculateEMI = () => {
        const principalAmount = loanAmount;
        const annualRate = interestRate;
        const monthlyRate = annualRate / 12 / 100;
        const tenureMonths = loanTenure * 12;

        const emi = monthlyRate
            ? (principalAmount * monthlyRate * Math.pow(1 + monthlyRate, tenureMonths)) /
            (Math.pow(1 + monthlyRate, tenureMonths) - 1)
            : principalAmount / tenureMonths;

        const totalPayment = emi * tenureMonths;
        const interest = totalPayment - principalAmount;

        setEmiDetails({
            totalPayment: totalPayment.toFixed(2),
            emi: emi.toFixed(2),
            principal: principalAmount.toFixed(2),
            interest: interest.toFixed(2),
        });
    };

    // Update EMI details when inputs change
    useEffect(() => {
        calculateEMI();
    }, [loanAmount, interestRate, loanTenure]);

    // Chart Data
    const chartData = {
        labels: ["Payable Amount", "Interest Payable"],
        datasets: [
            {
                data: [emiDetails.principal, emiDetails.interest],
                backgroundColor: ["#FFA500", "#87CEEB"],
            },
        ],
    };

    return (
        <>
            {/* <div>
               
                <img src={Image1} className="d-block w-100 personal-image" alt="EMI Calculator" />
                <div className="image-text-auto-cibil">
                    <h1 className="emi-image-head text-dark">EMI - CALCULATOR</h1>
                    <p className="text-start">
                        <Link className="about-route-home text-dark" to="/">
                            HOME /
                        </Link>
                        <span className="homeloan-page-r"> EMI Calculator</span>
                    </p>
                </div>
            </div> */}

            <div class="emi-banner">
                <div class="container emi-banner-content">
                    <p className="text-start">
                        <h1 className="about-route-head">EMI-CALCULATOR</h1>
                        <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r"> EMI Calculator</span>
                    </p>
                </div>
            </div>

            {/* EMI Calculator Section */}
            <div className="calculator container emi-calculator-con">
                {/* <h2>Emi-Calculator</h2> */}
                <div className="row mt-4">
                    <div className="col-md-6 mb-4">
                        <div className="emi-total-con p-5">
                            <div className="d-flex justify-content-between">
                                <label htmlFor="loanAmount" className="form-label">
                                    Loan Amount
                                </label>

                                <div className="value-display">₹{loanAmount}</div>
                            </div>

                            <input
                                type="range"
                                className="form-range form-slide-range"
                                id="loanAmount"
                                min="20000"
                                max="5000000"
                                step="10000"
                                value={loanAmount}
                                onChange={(e) => setLoanAmount(Number(e.target.value))}
                            />


                            <div className="d-flex justify-content-between mt-4">
                                <label htmlFor="interestRate" className="form-label ">
                                    Interest Rate (%)
                                </label>

                                <div className="value-display">{interestRate}%</div>
                            </div>
                            <input
                                type="range"
                                className="form-range form-slide-range"
                                id="interestRate"
                                min="0.1"
                                max="15"
                                step="0.1"
                                value={interestRate}
                                onChange={(e) => setInterestRate(Number(e.target.value))}
                            />
                            <div className="d-flex justify-content-between mt-4">
                                <label htmlFor="loanTenure" className="form-label">
                                    Loan Term (Years)
                                </label>

                                <div className="value-display">{loanTenure} Years</div>
                            </div>
                            <input
                                type="range"
                                className="form-range"
                                id="loanTenure"
                                min="0.5"
                                max="30"
                                step="0.5"
                                value={loanTenure}
                                onChange={(e) => setLoanTenure(Number(e.target.value))}
                            />
                        </div>
                        {/* Breakdown of EMI */}
                        <div className="breakdown emi-total-con mt-4">
                            <div className="d-flex justify-content-between break-emi-value">
                                <p className="break-emi-content-1">Monthly EMI</p>
                                <p className="break-emi-content-2"> ₹ {emiDetails.emi}</p>
                            </div>

                            <div className="d-flex justify-content-between break-emi-value">
                                <p className="break-emi-content-1">Total Interest</p>
                                <p className="break-emi-content-2"> ₹ {emiDetails.interest}</p>
                            </div>

                            <div className="d-flex justify-content-between break-emi-value">
                                <p className="break-emi-content-1">Payable Amount</p>
                                <p className="break-emi-content-2"> ₹ {emiDetails.principal}</p>
                            </div>

                            <div className="d-flex justify-content-between break-emi-value">
                                <p className="break-emi-content-1 text-start">Total Amount
                                    <br />
                                    (Payable amount + Interest)</p>
                                <p className="break-emi-content-2"> ₹ {emiDetails.totalPayment}</p>
                            </div>
                        </div>
                    </div>

                    {/* EMI Results and Chart */}
                    <div className="col-md-6">
                        <div className="results">
                            {/* Doughnut Chart */}
                            <div className="chart mb-3">
                                <Doughnut data={chartData} />
                            </div>
                            <div className="mt-4">
                                <button className="emi-btn mt-4">APPLY</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Emi_cal;
