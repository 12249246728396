import React from "react";
import Image1 from '../../../Assets/images/loanbest02.jpg';
import Image2 from '../../../Assets/images/Banner-new-01.webp';
import Image3 from '../../../Assets/images/blog_banner-3.jpg';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faScaleUnbalanced, faReceipt, faHotel } from '@fortawesome/free-solid-svg-icons';
import { faBuildingColumns, faRecycle, faFaceSmile, faMoneyBillTrendUp, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

function Images() {

    return (
        <>
            <div id="carouselExampleFade" class="carousel slide carousel-fade mt-0" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="3000">
                        <img src={Image1} class="d-block w-100 custom-carousel-image opacity-75" alt="..." />
                        <div class="carousel-caption carousel-caption-1 text-start">
                            <h1 className="carusoul-header">Business Loan to Suit<br /> Your Needs.</h1>
                            <small>The low rate you need for the need you want ! call</small><br />
                            <p className="carusoul-contact-no">+91 7358515353</p>
                            <Link to={'/business-loan'}> <button className="carusoul-button">APPLY NOW</button><br /></Link>
                        </div>
                    </div>
                    <div class="carousel-item" data-bs-interval="3000">
                        <img src={Image2} class="d-block w-100 custom-carousel-image opacity-75" alt="..." />
                        <div class="carousel-caption carousel-caption-2 text-start">
                            <h1 className="carusoul-header">Home Loans With Lowest<br /> Intrest Rates <span className="carusoul-rate">6.9%</span></h1>
                            <small>Get the best Home Loan in town.Compare and get  a loan at competitive intrest<br /> rates. </small><br />
                            <small>Find The right loan by using home calculators.</small><br />
                            <Link to={'/home-loan'}> <button className="carusoul-button mt-2">APPLY NOW</button><br /></Link>
                        </div>
                    </div>
                    <div class="carousel-item" data-bs-interval="3000">
                        <img src={Image3} class="d-block w-100 custom-carousel-image opacity-75" alt="..." />
                        <div class="carousel-caption carousel-caption-3 text-start">
                            <h1 className="carusoul-header">Buying a car has never been<br /> easier intrest rate starting
                                from<br /> <span className="carusoul-rate">8.50 %</span></h1>
                            <small>We offer car loans at competitive terms that come with a range of value-added
                                benefits. With an approval process <br />that's simple and easy ,buying a car has never been easier.</small><br />
                            <Link to={'/auto-loan'}> <button className="carusoul-button mt-2">APPLY NOW</button><br /></Link>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    {/* <span class="carousel-control-prev-icon carusol-arrows" aria-hidden="true"></span> */}
                    <FontAwesomeIcon icon={faChevronLeft} className="carusol-arrows" />
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                    <FontAwesomeIcon icon={faChevronRight} className="carusol-arrows" />
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
            <div className="banner-bottom-color-icon-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 bussiness-banner-con-1 pt-2">
                            <FontAwesomeIcon icon={faBuildingColumns} className="mt-1" />
                            <p>50+ Banks</p>
                        </div>
                        <div className="col-md-3 bussiness-banner-con-1 pt-2">
                            <FontAwesomeIcon icon={faRecycle} className="mt-1" />
                            <p>Hassle free service</p>
                        </div>
                        <div className="col-md-3 bussiness-banner-con-1 pt-2">
                            <FontAwesomeIcon icon={faFaceSmile} className="mt-1" />
                            <p>1000 + Happy customers</p>
                        </div>
                        <div className="col-md-3 bussiness-banner-con-1 pt-2">
                            <FontAwesomeIcon icon={faMoneyBillTrendUp} className="mt-1" />
                            <p>Exclusive Offers & Rates</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner-bottom-color-icons p-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 business-col-container">
                            <Link to={'/business-loan'} className="banner-sub-link">
                                <div className="bussiness-banner-con">
                                    <div>
                                        <FontAwesomeIcon icon={faBriefcase} className="bussiness-banner-icon mt-3" />
                                    </div>
                                    <div>
                                        <span className="bussiness-offer-let">10.25%</span><br />
                                        <span className="bussiness-name-content">BUSSINESS LOAN</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 business-col-container">
                            <Link to={'/personal-loan'} className="banner-sub-link">
                                <div className="bussiness-banner-con">
                                    <div>
                                        <FontAwesomeIcon icon={faScaleUnbalanced} className="bussiness-banner-icon mt-3" />
                                    </div>
                                    <div>
                                        <span className="bussiness-offer-let">10.50%</span><br />
                                        <span className="bussiness-name-content">PERSONAL LOAN</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 business-col-container">
                            <Link to={'/PrivateCheque-Loan'} className="banner-sub-link">
                                <div className="bussiness-banner-con-2">
                                    <div>
                                        <FontAwesomeIcon icon={faReceipt} className="bussiness-banner-icon mt-3" />
                                    </div>
                                    <div>
                                        <span className="bussiness-offer-let">18.00%</span><br />
                                        <span className="bussiness-name-content">PRIVATE CHEQUE LOAN</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 business-col-container">
                            <Link to={'/home-loan'} className="banner-sub-link">
                                <div className="bussiness-banner-con">
                                    <div>
                                        <FontAwesomeIcon icon={faHotel} className="bussiness-banner-icon mt-3" />
                                    </div>
                                    <div>
                                        <span className="bussiness-offer-let">8.75%</span><br />
                                        <span className="bussiness-name-content">HOME & LAP LOAN</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Images;