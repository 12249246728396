import React, { useState, useEffect } from "react";
import Navbar from "../../component/navbar";
import './index.css';
import Title from "../../component/title";
import { Link } from "react-router-dom";
import About_Card from "../../component/about/about-card";
import About_Cost from "../../component/about/about-cost";
import About_Team from "../../component/about/about-team";
import Footer from "../../component/footer";
import About_Count from "../../component/about/about-count";
import About_Images from "../../component/about/image";
import Loading from "../../component/loading";

function AboutUs() {
  const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>

            <Title title="Skanda Fincorp :: ABOUT-US">
                <Navbar />

                <About_Images />
                {isLoading ? (
                    <Loading />

                ) : (
                    <>
                        <div className="container mt-4">
                            <About_Card />

                            <About_Cost />
                        </div>

                        <About_Count />
                        {/* <div className="container">
                    <About_Team />
                </div> */}
                        <Footer />
                    </>
                )}
            </Title>

        </>
    )
}

export default AboutUs;