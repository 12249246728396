import React from "react";
import './index.css';
import Image1 from '../../../Assets/images/machinary-loan.jpg';
import { Link } from "react-router-dom";

function Machinery_Loan_Image() {

    return (
        <>
            {/* <div>
                <img src={Image1} className="d-block w-100 personal-image" alt="..." />

                <div className="image-text-home">
                    <h1 className="about-route-head">MACHINERY LOAN</h1>
                    <p className="text-start">
                        <Link className="about-route-home" to="/"> HOME / </Link>
                        <span className="homeloan-page-r"> Machinery Loan</span>
                    </p>
                </div>
            </div> */}
            <div class="machinery-banner">
                <div class="container machinery-banner-content">
                    <p className="text-start">
                        <h1 className="about-route-head"><strong>MACHINERY LOAN</strong></h1>
                        <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r"> Machinery Loan</span>
                    </p>
                </div>
            </div>
        </>
    );
}

export default Machinery_Loan_Image;
