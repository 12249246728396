import React, { useState, useEffect } from "react";
import Navbar from "../../component/navbar";
// import './index.css';
import Title from "../../component/title";
import Footer from "../../component/footer";
import Cibil_About from "../../component/cibil-score";
import Cibil_ApplyForm from "../../component/cibil-score/apply";
import Loading from "../../component/loading";

function Cibil_Score() {
 const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>
    
            <Title title="Cibil-Score | Skanda Fincorp">
                
                <Navbar />

                <Cibil_About />

                {isLoading ? (
                    <Loading />

                ) : (
                    <>
                <div className="container">
                    <Cibil_ApplyForm />
                </div>

                <Footer />
                </>
                )}
            </Title>
        
        </>
    )
}

export default Cibil_Score;