import React from "react";
import './index.css';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartPulse, faHouseChimneyCrack, faCarBurst, faBedPulse } from '@fortawesome/free-solid-svg-icons';

function Personal_Form() {

    return (
        <>
            <div class="form-container mt-4 mb-4">
                <div className="card p-5 border-0 form-card">
                    <h2 class="form-header-con">
                        Get best personal loan offers
                    </h2>
                    <p className="text-secondary">Now apply for a Personal Loan online, All you need to do
                        is provide your details below application form.</p>
                    <form action="#" >
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" >Name</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">E-Mail</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">Place</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">Phone</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" className="form-name">Loan Amount</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" className="form-name-1">Year</label>
                            </div>
                        </div>
                        <button className="form-btn-page mt-4">Submit New Quote</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Personal_Form;
