import React, { useState, useEffect } from "react";
import Navbar from "../../../component/navbar";
// import './index.css';
import Title from "../../../component/title";
import Footer from "../../../component/footer";
import Gold_Loan_Image from "../../../component/Loans/Gold-Loan";
import Gold_Loan_Apply from "../../../component/Loans/Gold-Loan/apply";
import Loading from "../../../component/loading";

function Gold_Loan() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>

            <Title title="Gold Loan | Skanda Fincorp">

                <Navbar />

                <Gold_Loan_Image />
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="container mt-4 mb-4">

                            <Gold_Loan_Apply />

                        </div>

                        <Footer />
                    </>
                )}
            </Title>

        </>
    )
}

export default Gold_Loan;