import React, { useRef, useState } from "react";
import './index.css';
import Image1 from '../../../Assets/images/home-loan1.jpg';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Image2 from '../../../Assets/images/business-loan.jpeg';
import Image3 from '../../../Assets/images/odcc-2-img.jpg';
import Image4 from '../../../Assets/images/privatecheque.jpg';
import Image5 from '../../../Assets/images/Personal-Loan.jpg';
import Image6 from '../../../Assets/images/ODCC-img.avif';
import Image7 from '../../../Assets/images/property-loan1.jpg';
import Image8 from '../../../Assets/images/car-loan-12.jpg';
import Image9 from '../../../Assets/images/gold-loan.jpg';
function Machinery_Loan_Apply() {

        const [activeStep, setActiveStep] = useState(1);
    
        const handleStepClick = (step) => {
            setActiveStep(step);
        };
    
        const [activeTab, setActiveTab] = useState("credit");
    
        const handleTabClick = (tab) => {
            setActiveTab(tab);
        };
    
        const containerRef = useRef(null);
        const containerRef1 = useRef(null);
        const totalSteps = 6; 
        const visibleSteps = 3;
        const stepWidth = 300; 
        const maxScroll = (totalSteps - visibleSteps) * stepWidth;
    
        const handleScroll = (direction) => {
            if (containerRef.current) {
                const stepWidth = 300; 
                const currentScroll = containerRef.current.scrollLeft;
    
                const scrollAmount = direction === 'next' ? stepWidth : -stepWidth;
                const newScrollPosition = currentScroll + scrollAmount;
    
                if (newScrollPosition >= 0 && newScrollPosition <= maxScroll) {
                    containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
                }
            }
        };
    
    
        const handleScroll1 = (direction) => {
            if (containerRef1.current) {
                const scrollAmount = direction === "next" ? 300 : -300;
                containerRef1.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
            }
        };

    return (
        <>

            <div className="card p-3 text-start border-0 personal-about-card">

                <h2 className="personal-card-header">About Machinery Loan</h2>

                <p className="personal-card-para">Continuous upgrades are the secret behind successful
                    businesses, be it small, medium, or large. When it comes to certain ventures, there
                    would be frequent needs of purchasing different equipment, which help optimize
                    overall efficiency. We, at Skanda Fincorp, aim to help businesses in
                    these cases.</p>
                <p className="personal-card-para">Machinery loans or Equipment loans are provided
                    to businesses who aim to increase their overall productivity along with planned
                    profits. The equipment varies from Earthmoving equipment, CNC machines,
                    industrial equipment, medical devices, dental tools, cranes, precision cutting
                    tools, etc.</p>
            </div>

            <div className="card p-3 text-start border-0 personal-about-card">

                <div className="row">
                    <div className="col-md-12 d-flex">
                        <button
                            className="carousel-btn prev"
                            onClick={() => handleScroll("prev")}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <ul className="business-card-ul" ref={containerRef}>
                            <li
                                className={`step ${activeStep === 1 ? "active" : ""}`}
                                onClick={() => handleStepClick(1)}
                            >
                                <span className="step-title">Benefits</span>
                            </li>
                            <li
                                className={`step ${activeStep === 2 ? "active" : ""}`}
                                onClick={() => handleStepClick(2)}
                            >
                                <span className="step-title">Features</span>
                            </li>
                            <li
                                className={`step ${activeStep === 5 ? "active" : ""}`}
                                onClick={() => handleStepClick(5)}
                            >
                                <span className="step-title">Machinery Loan Eligibility</span>
                            </li>
                            <li
                                className={`step ${activeStep === 6 ? "active" : ""}`}
                                onClick={() => handleStepClick(6)}
                            >
                                <span className="step-title">Disclaimer</span>
                            </li>

                        </ul>
                        <button
                            className="carousel-btn next"
                            onClick={() => handleScroll("next")}
                            disabled={containerRef.current?.scrollLeft >= (totalSteps - visibleSteps) * stepWidth}
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                    <div className="col-md-12 card p-4 mt-4 rounded-0">
                       
                        {activeStep === 1 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Benefits</h6>
                                <p className="business-hr"></p>
                                <small><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Both secured & unsecured loans are available.</small><br />
                                <small><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Loan amount:</strong> Unsecured loan - Up to Rs 1 crore; Secured loans - Up to Rs 5 crore.</small>
                                <br />
                                <small><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Loan tenure:</strong> Unsecured loan – Up to 5 years; Secured loans - Up to 15 years.</small><br />

                                <small>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Unsecured loan facilities include term loans & working capital loans.
                                </small> <br />
                                <small>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Secured loan facilities include Cash Credit A/c & Working Capital Loans.
                                </small><br />
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div className="text-start">
                                <h6 className="business-header-about"> Features</h6>
                                <p className="business-hr"></p>
                                <small><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Both secured & unsecured loans are available.</small><br />
                                <small><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Loan amount:</strong> Unsecured loan - Up to Rs 1 crore; Secured loans - Up to Rs 5 crore.</small>
                                <br />
                                <small><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Loan tenure:</strong> Unsecured loan – Up to 5 years; Secured loans - Up to 15 years.</small><br />

                                <small>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Unsecured loan facilities include term loans & working capital loans.
                                </small> <br />
                                <small>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Secured loan facilities include Cash Credit A/c & Working Capital Loans.
                                </small><br />
                            </div>
                        )}
                        {activeStep === 5 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Business Loan Eligibility</h6>
                                <p className="business-hr"></p>
                                <small><strong><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Age:</strong> 21 - 60 years.</small><br />
                                <small><strong><FontAwesomeIcon icon={faCircle} className="business-dot-icon" />  Business Vintage:</strong>1 year & above.</small><br />
                                <small><strong><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Credit Score:
                                </strong> A credit score of 675 & above increase the chances of loan approval and at lower interest rates.</small><br />
                            </div>
                        )}
                        {activeStep === 6 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Disclaimer</h6>
                                <p className="business-hr"></p>
                                <small>  Skanda Fincorp acts as a loan aggregator, connecting you with our network of trusted lending partners.
                                </small>
                            </div>
                        )}
                    </div>
                </div>
            </div >

            <div className="carousel-container">
                <button
                    className="carousel-btn1 prev"
                    onClick={() => handleScroll1("prev")}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <div className="carousel-wrapper d-flex" ref={containerRef1}>
                    <div className="product-card">
                        <img src={Image2} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Business Loan</p>
                        <Link to={'/business-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image3} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">OD & CC Loan</p>
                        <Link to={'/ODCC-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image4} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Private Cheque Loan</p>
                        <Link to={'/PrivateCheque-Loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image5} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Personal Loan</p>
                        <Link to={'/personal-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image6} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Home Loan</p>
                        <Link to={'/home-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image7} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Loan Against Property</p>
                        <Link to={'/property-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image8} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Auto Loan</p>
                        <Link to={'/auto-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image9} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Gold Loan</p>
                        <Link to={'/gold-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                </div>
                <button
                    className="carousel-btn1 next"
                    onClick={() => handleScroll1("next")}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
            </div>

            <div class="form-container mt-4 mb-4">
                <div className="card p-5 border-0 form-card">
                    <h2 class="form-header-con">
                        Get a Quote
                    </h2>
                    <p className="text-secondary">Now apply for a Machinery Loan online,
                        All you need to do is provide your details below application form.</p>
                    <form action="#" >
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" >Name</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">E-Mail</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">Place</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">Phone</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" className="form-name">Loan Amount</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" className="form-name-1">Year</label>
                            </div>
                        </div>
                        <button className="form-btn-page mt-4">Apply Now</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Machinery_Loan_Apply;
