import React, { useRef, useState } from "react";
import './index.css';
import Image1 from '../../../Assets/images/home-loan1.jpg';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Image2 from '../../../Assets/images/business-loan.jpeg';
import Image3 from '../../../Assets/images/odcc-2-img.jpg';
import Image4 from '../../../Assets/images/privatecheque.jpg';
import Image5 from '../../../Assets/images/Personal-Loan.jpg';
import Image6 from '../../../Assets/images/property-loan1.jpg';
import Image7 from '../../../Assets/images/car-loan-12.jpg';
import Image8 from '../../../Assets/images/machinary-loan.jpg';
import Image9 from '../../../Assets/images/gold-loan.jpg';

function Home_Loan_Apply() {

    const [activeStep, setActiveStep] = useState(1);

    const handleStepClick = (step) => {
        setActiveStep(step);
    };

    const [activeTab, setActiveTab] = useState("credit");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const containerRef = useRef(null);
    const containerRef1 = useRef(null);
    const totalSteps = 6; 
    const visibleSteps = 3; 
    const stepWidth = 300;
    const maxScroll = (totalSteps - visibleSteps) * stepWidth;

    const handleScroll = (direction) => {
        if (containerRef.current) {
            const stepWidth = 300;
            const currentScroll = containerRef.current.scrollLeft;

            const scrollAmount = direction === 'next' ? stepWidth : -stepWidth;
            const newScrollPosition = currentScroll + scrollAmount;

            if (newScrollPosition >= 0 && newScrollPosition <= maxScroll) {
                containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            }
        }
    };


    const handleScroll1 = (direction) => {
        if (containerRef1.current) {
            const scrollAmount = direction === "next" ? 300 : -300; 
            containerRef1.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
        }
    };

    return (
        <>

            <div className="card p-3 text-start border-0 personal-about-card">

                <h2 className="personal-card-header">About Home Loan</h2>

                <p className="personal-card-para">It is almost everyone’s dream to own a small building which they can call home.
                    You struggle every day to save a penny and go a step further to your dream.
                    What if you can make this dream a reality a bit soon? We, at Skanda Fincorp,
                    value your emotion and provide an extra hand to make your dream a reality.</p>
                <p className="personal-card-para">Home loan or Housing loan is provided to buy/construct a house or expand/repair/renovate the
                    existing house. This is a secured loan, as the property involved will be pledged to the
                    lender until the borrower repays the entire amount along with the specified interest.
                    The amount borrowed under housing loan will have interest rates starting from 8.75% in
                    India and the tenure can stretch up to 30 years.</p>
            </div>

            <div className="card p-3 text-start border-0 personal-about-card">

                <div className="row">
                    <div className="col-md-12 d-flex">
                        <button
                            className="carousel-btn prev"
                            onClick={() => handleScroll("prev")}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <ul className="business-card-ul" ref={containerRef}>
                            <li
                                className={`step ${activeStep === 1 ? "active" : ""}`}
                                onClick={() => handleStepClick(1)}
                            >
                                <span className="step-title">Benefits</span>
                            </li>
                            <li
                                className={`step ${activeStep === 2 ? "active" : ""}`}
                                onClick={() => handleStepClick(2)}
                            >
                                <span className="step-title">Features</span>
                            </li>
                            <li
                                className={`step ${activeStep === 5 ? "active" : ""}`}
                                onClick={() => handleStepClick(5)}
                            >
                                <span className="step-title">Home Loan Eligibility</span>
                            </li>
                            <li
                                className={`step ${activeStep === 6 ? "active" : ""}`}
                                onClick={() => handleStepClick(6)}
                            >
                                <span className="step-title">Disclaimer</span>
                            </li>

                        </ul>
                        <button
                            className="carousel-btn next"
                            onClick={() => handleScroll("next")}
                            disabled={containerRef.current?.scrollLeft >= (totalSteps - visibleSteps) * stepWidth}
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                    <div className="col-md-12 card p-4 mt-4 rounded-0">
                     
                        {activeStep === 1 && (
                            <div className="text-start">
                                <h6 className="business-header-about"> Benefits</h6>
                                <p className="business-hr"></p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong> Wide Range of Approved Projects:</strong> Choose your dream home from our extensive network of approved projects across India.
                                </p>
                                <h6 className="business-header-about"> Terms and Conditions</h6>
                                <p className="business-hr"></p>
                                <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Maximum Loan Amount:</strong> Loan up to Rs. 35 lakhs for locations with a
                                    population above 10 lakhs and property value exceeding Rs. 10 lakhs.</p>
                                <h6 className="business-header-about"> Other Conditions</h6>
                                <p className="business-hr"></p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Skanda Finance will convey its decision within 30 working days from the date of receipt of the application,
                                    provided the application is complete in all aspects.
                                </p>
                               
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div className="text-start">
                                {/* <h6 className="business-header-about"> Features</h6>
                                <p className="business-hr"></p> */}
                                
                                <h6 className="business-header-about">Why Choose Skanda Fincorp for Home Loan Based Finance?</h6>
                                <p className="business-hr"></p>

                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Call to Action:</strong> Add a clear call to action button like "Apply Now" or "Get Pre-Approved" to encourage user engagement.</p>
                                <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Eligibility Criteria:</strong> Briefly mention the eligibility criteria for applying for a home loan with Skanda Finance.</p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong> Competitive Advantages:</strong> Highlight any unique advantages offered by Skanda Finance, such as competitive interest rates,
                                    flexible repayment options, or personalized customer service.</p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Customer Testimonials:</strong> Include positive testimonials from satisfied customers to build trust and credibility.</p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Contact Information:</strong> Provide clear contact information, including phone number, email address,
                                    and office address, for easy accessibility.
                                </p>
                            </div>
                        )}
                        {activeStep === 5 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Home Loan Eligibility</h6>
                                <p className="business-hr"></p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Individuals with a combined income of Rs. 8,000 or above.</p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Minimum combined income of Rs. 8,000 per month for salaried, professionals, and self-employed individuals.</p>
                                <h6 className="business-header-about">Home Loan Details</h6>
                                <p className="business-hr"></p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Minimum Loan Amount:</strong> Rs. 1 Lakh</p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Maximum Loan Amount:</strong> Up to 20+ Crore</p>
                                <h6 className="business-header-about">Borrowing Limits</h6>
                                <p className="business-hr"></p>
                                <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> 10% margin on loans up to 20+ Crore</p>
                                <h6> <strong>Additional Considerations for your Skanda Finance Website:</strong></h6>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Call to Action:</strong> Add a clear call to action button like "Apply Now" or "Get Pre-Approved" to encourage user engagement.
                                </p>

                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Eligibility Criteria:</strong> Briefly mention the eligibility criteria for applying for a home loan with Skanda Finance.</p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Competitive Advantages:</strong> Highlight any unique advantages offered by Skanda Finance,
                                    such as competitive interest rates, flexible repayment options, or personalized customer service.</p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Customer Testimonials:</strong> Include positive testimonials from satisfied customers to build trust and credibility.</p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Contact Information:</strong> Provide clear contact information, including phone number, email address, and office address, for easy accessibility.</p>
                            </div>
                        )}
                        {activeStep === 6 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Disclaimer</h6>
                                <p className="business-hr"></p>
                                <p> Skanda Finance acts as a loan aggregator and is authorized to provide services on behalf
                                    of its lending partners. We do not lend money directly. Loan decisions are made solely
                                    by our lending partners based on their individual eligibility criteria and risk assessment.
                                    Skanda Finance is not responsible for any decisions made by our lending partners
                                    regarding loan applications.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div >

            <div className="carousel-container">
                <button
                    className="carousel-btn1 prev"
                    onClick={() => handleScroll1("prev")}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <div className="carousel-wrapper d-flex" ref={containerRef1}>
                    <div className="product-card">
                        <img src={Image2} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Business Loan</p>
                        <Link to={'/business-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image3} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">OD & CC Loan</p>
                        <Link to={'/ODCC-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image4} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Private Cheque Loan</p>
                        <Link to={'/PrivateCheque-Loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image5} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Personal Loan</p>
                        <Link to={'/personal-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image6} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Loan Against Property</p>
                        <Link to={'/property-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image7} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Auto Loan</p>
                        <Link to={'/auto-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image8} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Machinery Loan</p>
                        <Link to={'/machinery-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image9} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Gold Loan</p>
                        <Link to={'/gold-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                </div>
                <button
                    className="carousel-btn1 next"
                    onClick={() => handleScroll1("next")}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
            </div>
        </>
    );
}

export default Home_Loan_Apply;
