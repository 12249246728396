import React from "react";
import './index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faPeopleGroup, faCalculator } from "@fortawesome/free-solid-svg-icons";

function Banner() {

    return (
        <>
            <div className="container mt-4">
                <h4 className="banner-h1-header">Why Skanda-Fincorp?</h4>
                <p className="banner-p-content-1">We believe in every emotion and dream of our customers and are humbled to be a
                    positive part of their journey. With best features and hassle-free process, we
                    strive hard to make our relationship with the customers pure and trustworthy:
                </p>
                <div className="row mt-4 p-5">
                    <div className="col-md-4">
                    <FontAwesomeIcon icon={faPeopleGroup}  className="home-card-2-icon" />
                        <h1 className="banner-h1-header">Dedicated Specialists</h1>
                        <p className="banner-p-content">For every process we do, be it research, review, technical activities, etc.,
                            we have dedicated people who are experts in what they do. The open secret behind our seamless process is
                            these experts who make the work fool-proof.
                        </p>
                    </div>

                    <div className="col-md-4">
                    <FontAwesomeIcon icon={faTrophy}  className="home-card-2-icon" />
                        <h1 className="banner-h1-header">Personalized Advice</h1>
                        <p className="banner-p-content"> Have a problem or unsure of what to choose? No worries, our team
                            is here to assist you to choose the best products based on your needs and preferences. We continuously
                            research the market for best providers who can fetch you the best deals.
                        </p>
                    </div>

                    <div className="col-md-4">
                    <FontAwesomeIcon icon={faCalculator}  className="home-card-2-icon" />
                        <h1 className="banner-h1-header">Post-Sales Support </h1>
                        <p className="banner-p-content">Financial planning is a tricky business, and one must be cautious
                            in making a perfect plan. We at Skanda Fincorp not only help you with your current requirement but
                            also assist you in your future financial plans.
                        </p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Banner;