import React from "react";
import './index.css';
import Image1 from '../../../Assets/images/privatecheque.jpg';
import { Link } from "react-router-dom";

function PrivateCheque() {

    return (
        <>
            {/* <div>
                <img src={Image1} className="d-block w-100 personal-image" alt="..." />

                <div className="image-text-home">
                    <h1 className="about-route-head">PRIVATE CHEQUE LOAN</h1>
                    <p className="text-start">
                        <Link className="about-route-home" to="/"> HOME / </Link>
                        <span className="homeloan-page-r"> Private Cheque Loan</span>
                    </p>
                </div>
            </div> */}
            <div class="private-banner">
                <div class="container private-banner-content">
                    <p className="text-start">
                        <h1 className="banner-he-con"><strong>PRIVATE CHEQUE LOAN</strong></h1>
                        <Link className="about-route-home" to="/">HOME / </Link>
                        <span className="homeloan-page-r"> Private Cheque Loan</span>
                    </p>
                </div>
            </div>
        </>
    )
}

export default PrivateCheque;