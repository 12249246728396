import React from "react";
import './index.css';
import Image from "../../../Assets/images/about.jpg";

function About_Card() {

    return (
        <>
            <div className="row mb-4">
                <div className="col-md-6">
                    <img src={Image} alt="bk" height={'500px'} width={'600px'} className="img-fluid about-card-img" />
                </div>

                <div className="col-md-6 text-start mt-4">
                    <p className="about-card-box">About us</p>
                    {/* <h1 className="about-card-heater mt-2">We Help Our Clients To Grow Their Business</h1> */}
                    <p className="about-card-con-us-1 mt-4">We are into financial services for more than 15+ Years. We have a relationship with more
                         than 50 banks and NBFC’s. We have a solution for all the financial seekers at the correct time.
                    </p>
                    <p className="about-card-con-us">We offer end to end financial services to our clients such as  Business Loan,Working Capitals Loans OD(Over Draft) & CC(Cash Credit),Personal Loan, Home Loan, Loan Against Property,
                        Auto Loan, Machinery Loan, Agriculture Loan,Gold Loan, Insurance, Investments and Credit Cards.
                    </p>
                    <div class="container my-5 about-card-tap">
                        <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active navlink-about-three" id="story-tab" data-bs-toggle="tab" data-bs-target="#story" type="button" role="tab" aria-controls="story" aria-selected="true">Story</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link navlink-about-three" id="mission-tab" data-bs-toggle="tab" data-bs-target="#mission" type="button" role="tab" aria-controls="mission" aria-selected="false">Mission</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link navlink-about-three" id="vision-tab" data-bs-toggle="tab" data-bs-target="#vision" type="button" role="tab" aria-controls="vision" aria-selected="false">Vision</button>
                            </li>
                        </ul>

                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="story" role="tabpanel" aria-labelledby="story-tab">
                                <p>We are into financial and banking market.More than 15+ years in our career we organised and got disposements
                                    for our lot of existing privilege customers.Like as Bussiness Loan, Machinery Loan, Working Capital Loans.
                                    This is our Successfull story.  </p>
                            </div>
                            <div class="tab-pane fade" id="mission" role="tabpanel" aria-labelledby="mission-tab">
                                <p>We believe in putting control in your hands-simplifying the loan process so you can focus on achieving your goals with ease.</p>
                            </div>
                            <div class="tab-pane fade" id="vision" role="tabpanel" aria-labelledby="vision-tab">
                                <p>The Vision of Skanda Fincorp is to contribute to the sustainable development by providing responsible
                                    financial services and solutions to households and micro, small and medium enterprises and all type of customers</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default About_Card;