import React from "react";
import './index.css';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotesMedical, faAddressCard, faPlaneDeparture, faGraduationCap } from '@fortawesome/free-solid-svg-icons';

function Personal_Offer() {

    return (
        <>

            <div className="card text-start p-5 offer-card">

                <h2 className="offer-header">Personal Loan- Main Eligibility</h2>

                <p className="offer-content">During the loan application processing stage, lenders check your credit history
                    to understand your trust-worthiness in fulfilling your debt obligations. Credit
                    score of 750 or above shows that the borrower has demonstrated a consistent track
                    record of making timely repayments. Hence, lenders conclude that specified
                    borrower doesn’t fall under defaulter list and approve the personal loan
                    immediately.</p>

                <div className="row">

                    <div className="col-md-4">
                        <h4>Age</h4>
                        <p className="offer-content">Minimum age of Applicant: 21 years</p>
                        <h4></h4>
                    </div>
                    <div className="col-md-4">
                        <h4>Income</h4>
                        <p className="offer-content">Minimum net monthly income: Rs. 15,000 per month</p>
                    </div>
                    <div className="col-md-4">
                        <h4>Credit Rating</h4>
                        <p className="offer-content">Applicant should have the bank specified credit score.</p>
                    </div>

                </div>
            </div>




            <div className="card p-5 offer-card- mt-4 offer-card-loan">
                <h2 className="offer-header text-start">Personal Loan Offerings</h2>

                <p className="offer-content text-start">With the right kind of assistance at the right time, it’s time to achieve
                    all your goals and aspirations! Here are some of our personal loan
                    offerings:</p>

                <div className="row mt-4">
                    <div className="col-md-6">
                    <FontAwesomeIcon icon={faNotesMedical} className="home-card-2-icon"/>
                        <h4 className="mt-4">Medical Emergency</h4>
                        <p className="offer-content text-start">Borrow, a reputed loan company, offers personal
                            loans for any unexpected medical emergencies, instantly.</p>
                    </div>

                    <div className="col-md-6">
                    <FontAwesomeIcon icon={faAddressCard} className="home-card-2-icon"/>
                        <h4 className="mt-4">Wedding Ceremonies</h4>
                        <p className="offer-content text-start">Have a wedding ceremony planned and wondering about your budget. Worry no more! Here’s our
                            little gift for your dream wedding- an instant personal loan. Happy wedding!</p>
                    </div>
                    <div className="col-md-6">
                    <FontAwesomeIcon icon={faPlaneDeparture} className="home-card-2-icon" />
                        <h4 className="mt-4">Abroad Travel</h4>
                        <p className="offer-content text-start">Have a career planned abroad and paused due to the cash crunch? Head to Borrow,
                            get instant personal loan to cover all the travel expenditure, and climb the ladder
                            of your career towards success. Safe travel!</p>
                    </div>
                    <div className="col-md-6">
                    <FontAwesomeIcon icon={faGraduationCap} className="home-card-2-icon"/>
                        <h4 className="mt-4">Educations</h4>
                        <p className="offer-content text-start">Want to encourage your child to pursue higher studies but struggling to arrange
                            enough funds? Not anymore, as we provide personal loans for your child’s
                            higher education.</p>
                    </div>
                </div>

            </div>

        </>
    );
}

export default Personal_Offer;
