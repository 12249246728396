import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Home from './Views/Home';
import AboutUs from './Views/About';
import Services_Policy from './Views/Services';
import ContactUs from './Views/ContactUs';
import Insurance from './Views/Insurance';
import Personal_Loan from './Views/Loans/Personal-Loan';
import Home_Loan from './Views/Loans/Home-Loan';
import Business_Loan from './Views/Loans/Business-Loan';
import Property_Loan from './Views/Loans/Property-Loan';
import Auto_Loan from './Views/Loans/Auto-Loan';
import Machinery_Loan from './Views/Loans/Machinery-Loan';
import Education_Loan from './Views/Loans/Education-Loan';
import Gold_Loan from './Views/Loans/Gold-Loan';
import Invesment from './Views/Invesment';
import Cibil_Score from './Views/Cibil-score';
import EMI_Calculator from './Views/EMI-Cal';
import Career from './Views/Career';
import Private_Cheque from './Views/Loans/PrivateCheque-Loan';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/about-us' element={<AboutUs />}/>
        <Route path='/service' element={<Services_Policy />}/>
        <Route path='/contact-us' element={<ContactUs />}/>
        <Route path='/insurance' element={<Insurance />}/>
        <Route path='/personal-loan' element={<Personal_Loan />}/>
        <Route path='/home-loan' element={<Home_Loan />}/>
        <Route path='/business-loan' element={<Business_Loan />}/>
        <Route path='/property-loan' element={<Property_Loan />}/>
        <Route path='/auto-loan' element={<Auto_Loan />}/>
        <Route path='/machinery-loan' element={<Machinery_Loan />}/>
        <Route path='/ODCC-loan' element={<Education_Loan />}/>
        <Route path='/PrivateCheque-Loan' element={<Private_Cheque />}/>
        <Route path='/gold-loan' element={<Gold_Loan />}/>
        <Route path='/invesment' element={<Invesment />}/>
        <Route path='/cibil-score' element={<Cibil_Score />}/>
        <Route path='/emi-calculator' element={<EMI_Calculator />}/>
        <Route path='/career' element={<Career />}/>

      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
