import React, { useEffect, useState } from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUsers, faUserGear , faAward} from '@fortawesome/free-solid-svg-icons';

function About_Count() {

    const CountTo = ({ from, to, speed, refreshInterval, decimals, onUpdate, onComplete }) => {
        const [value, setValue] = useState(from);
    
        useEffect(() => {
            const loops = Math.ceil(speed / refreshInterval);
            const increment = (to - from) / loops;
            let loopCount = 0;
    
            const interval = setInterval(() => {
                setValue((prevValue) => {
                    const newValue = prevValue + increment;
    
                    if (onUpdate) onUpdate(newValue);
    
                    loopCount++;
    
                    if (loopCount >= loops) {
                        clearInterval(interval);
                        setValue(to);
    
                        if (onComplete) onComplete(to);
                    }
    
                    return newValue;
                });
            }, refreshInterval);
    
            return () => clearInterval(interval);
        }, [from, to, speed, refreshInterval, onUpdate, onComplete]);
    
        const formatter = (value) => {
            return value.toFixed(decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
        };
    
        return <div>{formatter(value)}</div>;
    };
    
    CountTo.defaultProps = {
        from: 0,
        to: 0,
        speed: 1000,
        refreshInterval: 100,
        decimals: 0,
        onUpdate: null,
        onComplete: null,
    };
    
    return (
        <div className=" about-count-container mb-4">
            <div className="row text-center">
                <div className="col">
                    <div className="counter bg-transparent">
                        <FontAwesomeIcon icon={faUsers} size='3x' />
                        <h2 className="timer count-title count-number">
                            <CountTo from={0} to={100} speed={1500} decimals={0} />
                        </h2>
                        <p className="count-text">Happy Clients</p>
                    </div>
                </div>
                <div className="col">
                    <div className="counter bg-transparent">
                        <FontAwesomeIcon icon={faCheck} size='3x' />
                        <h2 className="timer count-title count-number">
                            <CountTo from={0} to={1700} speed={1500} decimals={0} />
                        </h2>
                        <p className="count-text">Project Completed</p>
                    </div>
                </div>
                <div className="col">
                    <div className="counter bg-transparent">
                    <FontAwesomeIcon icon={faUserGear} size='3x' />
                                            <h2 className="timer count-title count-number">
                            <CountTo from={0} to={11900} speed={1500} decimals={0} />
                        </h2>
                        <p className="count-text">Dedicated Staff</p>
                    </div>
                </div>
                <div className="col">
                    <div className="counter bg-transparent">
                    <FontAwesomeIcon icon={faAward} size='3x' />
                                            <h2 className="timer count-title count-number">
                            <CountTo from={0} to={157} speed={1500} decimals={0} />
                        </h2>
                        <p className="count-text">Awards Achieved</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About_Count;
