import React, { useRef, useState } from "react";
import './index.css';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Image2 from '../../../Assets/images/business-loan.jpeg';
import Image3 from '../../../Assets/images/odcc-2-img.jpg';
import Image4 from '../../../Assets/images/Personal-Loan.jpg';
import Image5 from '../../../Assets/images/ODCC-img.avif';
import Image6 from '../../../Assets/images/property-loan1.jpg';
import Image7 from '../../../Assets/images/car-loan-12.jpg';
import Image8 from '../../../Assets/images/machinary-loan.jpg';
import Image9 from '../../../Assets/images/gold-loan.jpg';

function Private_Cheque_apply() {

    const [activeStep, setActiveStep] = useState(1);

    const handleStepClick = (step) => {
        setActiveStep(step);
    };

    const [activeTab, setActiveTab] = useState("credit");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const containerRef = useRef(null);
    const containerRef1 = useRef(null);
    const totalSteps = 6;
    const visibleSteps = 3;
    const stepWidth = 300;
    const maxScroll = (totalSteps - visibleSteps) * stepWidth;

    const handleScroll = (direction) => {
        if (containerRef.current) {
            const stepWidth = 300;
            const currentScroll = containerRef.current.scrollLeft;

            const scrollAmount = direction === 'next' ? stepWidth : -stepWidth;
            const newScrollPosition = currentScroll + scrollAmount;

            if (newScrollPosition >= 0 && newScrollPosition <= maxScroll) {
                containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            }
        }
    };


    const handleScroll1 = (direction) => {
        if (containerRef1.current) {
            const scrollAmount = direction === "next" ? 300 : -300;
            containerRef1.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
        }
    };

    return (
        <>

            <div className="card p-3 text-start border-0 personal-about-card">

                <h2 className="personal-card-header">Private Cheque Loan</h2>
                <p>In today's dynamic business landscape, seizing opportunities quickly is crucial for success. However,
                    unexpected expenses and urgent funding needs can often hinder growth. Skanda Fincorp's Cheque Based
                    Finance provides a swift and reliable solution to bridge these funding gaps.
                </p>
            </div>
            <div className="card p-3 text-start border-0 personal-about-card">

                <div className="row">
                    <div className="col-md-12 d-flex">
                        <button
                            className="carousel-btn prev"
                            onClick={() => handleScroll("prev")}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <ul className="business-card-ul" ref={containerRef}>

                            <li
                                className={`step ${activeStep === 1 ? "active" : ""}`}
                                onClick={() => handleStepClick(1)}
                            >
                                <span className="step-title">Benefits</span>
                            </li>
                            <li
                                className={`step ${activeStep === 2 ? "active" : ""}`}
                                onClick={() => handleStepClick(2)}
                            >
                                <span className="step-title">Features</span>
                            </li>
                            <li
                                className={`step ${activeStep === 5 ? "active" : ""}`}
                                onClick={() => handleStepClick(5)}
                            >
                                <span className="step-title">Private Cheque Loan Eligibility</span>
                            </li>
                            <li
                                className={`step ${activeStep === 6 ? "active" : ""}`}
                                onClick={() => handleStepClick(6)}
                            >
                                <span className="step-title">Disclaimer</span>
                            </li>

                        </ul>
                        <button
                            className="carousel-btn next"
                            onClick={() => handleScroll("next")}
                            disabled={containerRef.current?.scrollLeft >= (totalSteps - visibleSteps) * stepWidth}
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                    <div className="col-md-12 card p-4 mt-4 rounded-0">
                        {activeStep === 1 && (
                            <div>
                                <div>
                                    <h6 className="business-header-about mt-3">Benefits of Choosing Skanda Fincorp:</h6>
                                    <p className="business-hr"></p>
                                    <p>
                                        <strong> Full Transparency:</strong> We prioritize open communication and
                                        maintain complete transparency throughout the entire process.
                                    </p>
                                    <p>
                                        <strong>Fast Disbursal:</strong>Experience a swift loan approval and disbursement process within 48 hours.
                                    </p>
                                    <p>
                                        <strong> No Upfront Charges: </strong> Focus on growing your business without worrying about hidden costs.
                                    </p>

                                </div>
                            </div>
                        )}

                        {activeStep === 2 && (
                            <div>
                                <div className="text-start">
                                    <h6 className="business-header-about"> Why Choose Skanda Fincorp for Cheque Based Business Finance?</h6>
                                    <p className="business-hr"></p>
                                    <p>
                                        <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Collateral-Free Financing:</strong> Access funds without the burden of pledging assets.
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Turnover-Based Lending:</strong> Loan amounts are determined by your business's monthly turnover, ensuring you receive the right amount of support.
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Simplified Process:</strong> Minimal documentation and a streamlined application process for quick disbursal.
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Flexible Repayment Options:</strong> Choose repayment schedules that align with your cash flow.
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong> Competitive Interest Rates:</strong> Enjoy cost-effective financing to maximize your return on investment.
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>CIBIL-Friendly:</strong> Even businesses with lower CIBIL scores can qualify for our Cheque Based Finance.
                                    </p>
                                </div>
                            </div>
                        )}

                        {activeStep === 5 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Private Cheque Loan Eligibility</h6>
                                <p className="business-hr"></p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Age:</strong> 21 - 60 years.</p>
                                <p><FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Borrowing Limits:</strong> Minimum 1 Lakhs to Maxmimum 20+ crore </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Skanda Fincorp is your trusted partner for all your business financing needs. Our Cheque Based Finance is
                                    specifically designed to cater to the unique requirements of businesses of all sizes.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> <strong>Contact us today</strong> to learn more about our Cheque Based Finance solutions and unlock the potential of your business.
                                </p>
                            </div>
                        )}
                        {activeStep === 6 && (
                            <div className="text-start">
                                <h6 className="business-header-about">Disclaimer</h6>
                                <p className="business-hr"></p>
                                <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> This information is for general guidance only and should not be considered financial advice.</p>
                                <p> <FontAwesomeIcon icon={faCircle} className="business-dot-icon" /> Please consult with a financial professional for personalized recommendations.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div >

            <div className="carousel-container">
                <button
                    className="carousel-btn1 prev"
                    onClick={() => handleScroll1("prev")}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <div className="carousel-wrapper d-flex" ref={containerRef1}>
                    <div className="product-card">
                        <img src={Image2} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Business Loan</p>
                        <Link to={'/business-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image3} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">OD & CC Loan</p>
                        <Link to={'/ODCC-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image4} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Personal Loan</p>
                        <Link to={'/personal-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image5} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Home Loan</p>
                        <Link to={'/home-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image6} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Loan Against Property</p>
                        <Link to={'/property-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image7} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Auto Loan</p>
                        <Link to={'/auto-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image8} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Machinery Loan</p>
                        <Link to={'/machinery-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                    <div className="product-card">
                        <img src={Image9} alt="business loan" className='product-card-img' />
                        <p className="text-truncate">Gold Loan</p>
                        <Link to={'/gold-loan'}> <button className="carusoul-btn-main">Know More <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                    </div>
                </div>
                <button
                    className="carousel-btn1 next"
                    onClick={() => handleScroll1("next")}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
            </div>

            <div class="form-container mt-4 mb-4">
                <div className="card p-5 border-0 form-card">
                    <h2 class="form-header-con">
                        Apply Now
                    </h2>
                    <p className="text-secondary">Now apply for an Private Cheque Loan online, All you
                        need to do is provide your details below application form.</p>
                    <form action="#" >
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" >Name</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">E-Mail</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">Place</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="">Phone</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" className="form-name">Loan Amount</label>
                            </div>
                            <div class="input-data">
                                <input type="text" required />
                                <div class="underline"></div>
                                <label for="" className="form-name-1">Year</label>
                            </div>
                        </div>
                        <button className="form-btn-page mt-4">Apply Now</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Private_Cheque_apply;
