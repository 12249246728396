import React from "react";
import './index.css';
import Image1 from '../../../Assets/images/education-loan1.jpg';
import { Link } from "react-router-dom";

function Education_Loan_Image() {

    return (
        <>
            {/* <div>
                <img src={Image1} className="d-block w-100 personal-image" alt="..." />

                <div className="image-text-home">
                    <h1 className="about-route-head">OD&CC Loan</h1>
                    <p className="text-start">
                        <Link className="about-route-home" to="/"> HOME / </Link>
                        <span className="homeloan-page-r"> OD&CC Loan</span>
                    </p>
                </div>
            </div> */}

            <div class="odcc-banner">
                <div class="container odcc-banner-content">
                    <p className="text-start">
                        <h1 className=""><strong>OD&CC LOAN</strong></h1>
                        <Link className="about-route-home text-white" to="/">HOME / </Link>
                        <span className="homeloan-page-r text-white"> OD&CC Loan</span>
                    </p>
                </div>
            </div>

        </>
    );
}

export default Education_Loan_Image;
