import React from "react";
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faTrophy, faHeadset } from '@fortawesome/free-solid-svg-icons';
import Image from '../../../Assets/images/home-hover.jpg'
import Image1 from '../../../Assets/images/home-hover2.jpg'
import Image2 from '../../../Assets/images/home-hover-3.avif'

function Hover_card() {

    return (
        <>
            <div className="row cards mt-4 mb-4 text-start gap-5 ">
                <div className="card col-md-3 hover-card border-0 p-5">
                    <div className="card-hover-content">
                        <FontAwesomeIcon icon={faUsers} className="home-card-2-icon" />
                        <h1 className="card-hover-head mt-4"> Compare and apply</h1>
                        <hr className="card-hover-hr mt-4" />
                        <p className="card-hover-cont mt-4">Choose your kind of loan based on your preferences;
                            compare the loan offerings from the range of banks that we have and apply to the best
                            suitable one. We will review your application and if need be, we may reach out to you
                            for any clarifications or additional information.
                        </p>
                    </div>
                    {/* <div className="card-desc">
                        <img src={Image} alt="Hover" className="card-hover-image" />
                        <div className="card-hover-text">
                            <h2>Compare and apply</h2>
                            <p className="card-hover-image-desc">Choose your kind of loan based on your preferences;
                                compare the loan offerings from the range of banks that we have and apply to the best
                                suitable one. We will review your application and if need be, we may reach out to you for
                                any clarifications or additional information.
                            </p>
                        </div>
                    </div> */}
                </div>
                <div className="card col-md-3 hover-card border-0 p-5">
                    <div className="card-hover-content">
                        <FontAwesomeIcon icon={faTrophy} className="home-card-2-icon" />
                        <h1 className="card-hover-head mt-4">Approve your loan</h1>
                        <hr className="card-hover-hr mt-4" />
                        <p className="card-hover-cont mt-4">Post our review, banks check your CIBIL score,
                            evaluate your credit history, and conclude your creditworthiness. Higher the score,
                            faster is the loan approval.
                        </p>
                    </div>
                    {/* <div className="card-desc">

                        <img src={Image1} alt="Hover" className="card-hover-image" />
                        <div className="card-hover-text">
                            <h2>Approve your loan</h2>
                            <p className="card-hover-image-desc">Post our review, banks check your CIBIL score, evaluate your
                                credit history, and conclude your creditworthiness. Higher the score, faster is the
                                loan approval.
                            </p>
                        </div>
                    </div> */}
                </div>

                <div className="card col-md-3 hover-card border-0 p-5">
                    <div className="card-hover-content">
                        <FontAwesomeIcon icon={faHeadset} className="home-card-2-icon" />
                        <h1 className="card-hover-head mt-4">Get your cash</h1>
                        <hr className="card-hover-hr mt-4" />
                        <p className="card-hover-cont mt-4">Once we receive the signed agreement and confirmation from both,
                            funds will be shared to the registered bank account immediately. In case of any queries regarding
                            the pre-closure of your loan, feel free to reach out to us.
                        </p>
                    </div>
                    {/* <div className="card-desc">
                        <img src={Image2} alt="Hover" className="card-hover-image" />
                        <div className="card-hover-text">
                            <h2>Get your cash</h2>
                            <p className="card-hover-image-desc">Once we receive the signed agreement and confirmation from both,
                                funds will be shared to the registered bank account immediately. In case of any queries
                                regarding the pre-closure of your loan, feel free to reach out to us.
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Hover_card;