import React from "react";
// import "./index.css";

function Contact_ApplyForm() {
    return (
        <>

            <div className="card p-5 mb-4 mt-4 border-0 form-card">
                <h2 className="form-header">Get In Touch</h2>
                <p className="form-content">
                Reach out to us & we will respond as soon as we can.
                </p>
                <form className="apply-form">
                    <div className="form-row">
                        <input type="text" className="form-control" placeholder="Name" required />
                        <input type="email" className="form-control" placeholder="Email" required />
                        <input type="tel" className="form-control" placeholder="Phone" required />
                    </div>
                    <div className="form-row mt-4">
                        <textarea className="form-control message-box" placeholder="Message" rows="5" required></textarea>
                    </div>
                    <button type="submit" className="submit-button mt-4">SUBMIT</button>
                </form>
            </div>

        </>
    );
}

export default Contact_ApplyForm;
