import React, { useState, useEffect } from "react";
import Navbar from "../../../component/navbar";
// import './index.css';
import Title from "../../../component/title";
import Footer from "../../../component/footer";
import Education_Loan_Image from "../../../component/Loans/Education-Loan";
import Education_Loan_Apply from "../../../component/Loans/Education-Loan/apply";
import Loading from "../../../component/loading";

function Education_Loan() {
    const [isLoading, setIsLoading] = useState(true);
    
        useEffect(() => {
            const timeout = setTimeout(() => {
                setIsLoading(false);
            }, 500);
    
            return () => clearTimeout(timeout);
        }, []);

    return (
        <>
 
            <Title title="Education Loan | Skanda Fincorp">

                <Navbar />

                <Education_Loan_Image />
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                <div className="container mt-4 mb-4">

                    <Education_Loan_Apply />

                </div>

                <Footer />
                </>
                )}
            </Title>
        
        </>
    )
}

export default Education_Loan;