import React from "react";
import './index.css';
// import Image1 from '../../../Assets/images/card1.jpg';
import Image1 from '../../../Assets/images/about-us1.png';

import { NavLink } from "react-router-dom";

function Card() {

    return (
        <>
            <div className="container mt-4">
                <div className="row p-4 border">
                    <div className="col-md-6">
                        <img src={Image1} alt="..." height={'400px'} className="home-card-img-s"/>

                    </div>
                    <div className="col-md-6 text-start p-4">
                        {/* <h1 className="card-header-learn">A Few Words About Us</h1> */}
                        <h1 className="card-header-learn">Find loan products we offer</h1>
                        {/* <p className="card-content-learn mt-4">Skanda Fincorp was founded in 2024 and since that time has grown into the largest CPA firm in the area.
                            We are an assertive firm providing our clients a full range of services.</p>

                        <p className="card-content-learn mt-4">Our offices are located in the Intermountain West because this is where we chose to live. However,
                            we serve a wide variety of clients located throughout the United States and even some European countries.
                        </p> */}

                        <p className="card-content-learn mt-4">
                            Want to buy a home, car, expand your business, plan to Start a business,
                            etc., but have a shortage of cash? Worry not, as you can get instant loans with low
                            interest rate at Skanda Fincorp.
                        </p>

                        <p className="card-content-learn mt-4">"Dreaming of buying a home, a car, expanding your business, or funding Your Companies.
                            but facing a cash crunch? Don't worry! Get instant loans at low interest 
                            rates with Skanda Fincorp – your trusted financial partner."</p>


                        <button className="card-button-learn">
                            <NavLink to="/about-us" className='about-route-home text-light'> LEARN MORE </NavLink></button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Card;