import React from "react";
import './index.css';

function Loading() {
    return (
        <>
            <div className="loader-container">
                <div class="loader-1">
                    <div class="loader-2"></div>
                    <div class="loader-3"></div>
                    <div class="loader-4"></div>
                </div>
            </div>

        </>
    )
}

export default Loading;